import React, { Component } from "react";
import { Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Topnav = () => {
  return (
    <React.Fragment>
      <div className="bg-blue">
        <Container>
          <Row className="topnav">
            <div className="col-lg-9 col-3">
              <nav className="navbar h-100 navbar-expand-lg navbar-light">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler border-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#headerSupportedContent"
                    aria-controls="headerSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fa fa-ellipsis-v text-white"></i>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="headerSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/isbm-pro">ISBMU Pro</Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/media-coverage">Media</Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/library">e-Library</Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/research">Ph.D Research</Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/pay-fees-online">Pay Online</Link>
                      </li>
                      <li className="nav-item me-3">
                        <Link className="text-white" to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-lg-3 col-9">
              <div className="d-flex justify-content-end h-100 p-2">
                <a className="my-auto text-white me-3" href="tel:+9373199999">
                  +91-9373199999
                </a>
                <a
                  className="my-auto text-white btn-purple"
                  href="https://admission.isbmuniversity.in/"
                  target="_blank"
                >
                  APPLY NOW
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Topnav;
