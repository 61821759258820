import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import bannerImage from "../../Images/Banner/modalbanner.webp";
import { Link } from "react-router-dom";

class Modal1 extends Component {
  render() {
    const { show, onHide } = this.props;
    return (
      <Modal
        className="enquiry_formss "
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="custom-modal modal-xl"
      >
        <Modal.Header closeButton style={{ display: "initial" }}></Modal.Header>
        <Modal.Body className="abcd_modal_abcd">
          <img
            src={bannerImage}
            className="position-relative"
            alt="Banner"
            style={{ width: "100%" }}
          />
          <button type="button" class="bannerKnowMore border-0">
            <Link to="/programmes" className="text-white fs-5">
              Know More
            </Link>
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Modal1;
