import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import honor from "../../Images/Hon'ble Persons/HonbleGovernor1.webp";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const Visitor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <MetaTags>
        <title>Hon'ble Visitor of ISBM University</title>
        <meta
          name="description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <link rel="canonical" href="https://isbmuniversity.edu.in/honorable" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Hon'ble Visitor of ISBM University "
        />
        <meta
          property="og:description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/honorable/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Hon'ble Visitor of ISBM University"
        />
        <meta
          name="twitter:description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Professional Degrees , Recognized University , Accredited College"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Hon'ble Visitor</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hon'ble Visitor</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Row>
              <Col lg={4}>
                <div className="honor_div">
                  <img className="img_honor" src={honor} alt="Hon. Governor of Chhattisgarh Mr.Biswabhusan Harichandan"/>
                </div>
              </Col>
              <Col lg={8}>
                <h2 className="honor_name">Mr. Biswabhusan Harichandan</h2>
                <p className="text-justify">
                  It is innovation that can lead our country towards development
                  and help us attain a remarkable position on the world map.
                  Today, our survival in this competitive world depends on our
                  ability to innovate. It is said that the much-celebrated
                  Information Age is over and we are now in the Creative Era,
                  where the speed of innovation is the only key to sustainable
                  development. However, we must also remember that knowledge is
                  one of the most powerful tools for success, so invest your
                  time and efforts to identify appropriate competencies based on
                  your interests, aptitude, and social values.
                </p>
                <p className="text-justify">
                  It is good to adopt the scientific approaches of the West to
                  solve problems and for development, but we should not
                  underestimate our own Indian way of thinking and indigenous
                  philosophy of life which lays emphasis on "Vasudhaiva
                  Kutumbakam" and the well-being of every individual, i.e.,
                  "General Interest, General Happiness". We must remain deeply
                  connected to our roots and ethical bindings; this will help us
                  to succeed in life not only academically, but also as good
                  human beings and responsible citizens of the country.
                </p>
                <p className="text-justify">
                  The ambitious schemes, namely "Swachh Bharat", "Digital
                  India", "Make in India" and "Quality Education to All" are
                  also envisaged by the Honorable Prime Minister to attain our
                  goal of ancient glory and play a role of World Guru in the
                  near future. Keeping this in mind, we should strive hard in
                  the pursuit of progress and prosperity.
                </p>
                <p className="text-justify">My Best Wishes.</p>
                <a
                  href="https://rajbhavancg.gov.in"
                  target="_blank"
                  className="text-dark"
                >
                  https://rajbhavancg.gov.in
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Visitor;
