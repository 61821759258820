import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";


const Admissionhelpdesk  = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Admission Helpdesk</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Admission Helpdesk</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Admission Helpdesk</h2>
          <p className="mt-3">
              Please do feel free to contact us through any of the following
              means:
            </p>
            <p>
              Helpline No<a href="" className="text-dark"> +91-9373199999.</a> It shall be manned from
              Monday - Saturday between 10.30 AM to 6.00 PM
            </p>

            <p>
              Send us an email at<a href="" className="text-dark"> enquiry@isbmuniversity.edu.in</a>
            </p>

            <p>
              <b>Visit Us at Our Campus:</b>Village-Nawapara (Kosmi) Block,
              Tehsil-Chhura, District: Gariyaband, Chhattisgarh
            </p>

            <p>
              <b>Pin:</b> 493996
            </p>
            <p>
              <b>Contact:</b> +91 9373199999
            </p>
            <p>
              <b>Email:</b> info@isbmuniversity.edu.in
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Admissionhelpdesk;
