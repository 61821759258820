import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Extracurricular = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="extracurriculaminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Extra Curricular</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Extra Curricular</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Extra Curricular</h2>
            <p>
              The University has many auditoriums and seminar rooms equipped
              with the latest and state of the art audio visual facilities for
              guest lectures, seminars, presentations, meetings, faculty
              presentations, discussions and other academic activities.
            </p>
            <p>
              Students, coming from all over India get exposed to different
              religions and customs. We pamper these students conveniences by
              facilitating and connecting them with nearby providers of stay and
              accommodation services which provide amenities and groom them to
              manage their routine independently thus enabling them tune to the
              real aspects of life. Here, they can enjoy the comforts of reading
              newspapers and books with nutritious vegetarian and non-vegetarian
              food, safe drinking water and round the clock water supply thus
              giving them a feel of being at home.
            </p>
            <p>
              The physical development of the learners is equally important.
              Keeping this fact in mind, the University provides students with
              the ultra-modern facilities for sports and recreation. The campus
              has lot of space for indoor as well as outdoor games like Chess,
              Carrom-board, table-tennis, hockey, cricket, football, badminton,
              volleyball, aerobics, etc. Apart from this there are facilities for
              swimming, horse-riding etc. All this enhances the extra-curricular
              skills of the learners.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Extracurricular;
