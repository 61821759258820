import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AfterPostgraduate = () => {
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    const fetchCourseList = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/fetchAfterPostGraduationCourseList`
        );
        setCourseList(response.data.data);
      } catch (error) {
        console.error("Error fetching courseList:", error);
      }
    };

    fetchCourseList();
  }, []);

  return (
    <React.Fragment>
      {courseList.map((courseTitle) => (
        <li key={courseTitle.id} className="col-lg-5">
          <Link to={`/course-details/${courseTitle.name}`}>
            {courseTitle.title}
          </Link>
        </li>
      ))}
    </React.Fragment>
  );
};

export default AfterPostgraduate;
