import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import axios from "axios";
import $ from "jquery";
import EnquiryNow from "../common/EnquiryNow";

const Onlinepayment = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getData(e) {
    e.preventDefault();

    var name = $("#name1").val();
    var email = $("#email1").val();
    var amount = $("#amount1").val();
    var phone = $("#phone1").val();
    var course = $("#course1").val();
    var comment = $("#comment1").val();
    var orderid = Date.now();
    let input = {
      email: email,
      name: name,
      amount: amount,
      return_url: "https://isbmuniversity.edu.in/api/payment_response.php",
      order_id: orderid,
      mode: "LIVE",
      currency: "INR",
      description: "Payment Gateway",
      phone: phone,
      address_line_1: "Lalji Pada",
      address_line_2: "Kandivali West",
      city: "Mumbai",
      state: "Maharashtra",
      zip_code: "400051",
      country: "India",
      udf1: course,
      udf2: comment,
      udf3: "",
      udf4: "",
      udf5: "",
    };

    // console.log(input);
    await axios
      .post("https://isbmuniversity.edu.in/api/payment_proceed.php", input)
      .then((response) => {
        $('input[name="amount"]').val(amount);
        $('input[name="name"]').val(name);
        $('input[name="email"]').val(email);
        $('input[name="phone"]').val(phone);
        $('input[name="order_id"]').val(orderid);
        $('input[name="udf1"]').val(course);
        $('input[name="udf2"]').val(comment);
        $('input[name="hash"]').val(response.data.data);
        $("#tnpForm1").attr(
          "action",
          "https://pay.basispay.in/v2/paymentrequest"
        );
        $("#tnpForm1").submit();
      });
  }
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Pay Fee Online</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Pay Fee Online</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Pay Fee Online</h2>
            <Form id="tnpForm">
              <Row>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="name1"
                      name="name1"
                      placeholder="Enter Full Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="email1"
                      name="email"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="phone1"
                      name="phone"
                      placeholder="Enter Mobile Number"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Counselor Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Counselor Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Courses</Form.Label>
                    <select
                      required
                      name="course1"
                      id="course1"
                      className="form-control"
                    >
                      <option value="">Select Course</option>
                      <option value="School of Arts and Humanities">
                        School of Arts and Humanities
                      </option>
                      <option value="Bachelor of Arts (BA)">
                        &nbsp; &nbsp;- Bachelor of Arts (BA)
                      </option>
                      <option value="Bachelor of Social Work (BSW)">
                        &nbsp; &nbsp;- Bachelor of Social Work (BSW)
                      </option>
                      <option value="Bachelor of Arts ( Public Administration) (BA-PA)">
                        &nbsp; &nbsp;- Bachelor of Arts ( Public Administration)
                        (BA-PA)
                      </option>
                      <option value="Master of Arts (MA)">
                        &nbsp; &nbsp;- Master of Arts (MA)
                      </option>
                      <option value="Master of Arts- Public Administration / Economics / English">
                        &nbsp; &nbsp;- Master of Arts- Public Administration /
                        Economics / English
                      </option>
                      <option value="Master of Arts- Education">
                        &nbsp; &nbsp;- Master of Arts- Education
                      </option>
                      <option value="Master of Social Work (MSW)">
                        &nbsp; &nbsp;- Master of Social Work (MSW)
                      </option>
                      <option value="">
                        School of Management and Commerce
                      </option>
                      <option value="Diploma in Business Administration (DBA)">
                        &nbsp; &nbsp;- Diploma in Business Administration (DBA)
                      </option>
                      <option value="Bachelor of Business Administration (BBA)">
                        &nbsp; &nbsp;- Bachelor of Business Administration (BBA)
                      </option>
                      <option value="Bachelor of Commerce (BCom)">
                        &nbsp; &nbsp;- Bachelor of Commerce (BCom)
                      </option>
                      <option value="Master of Business Administration (MBA)">
                        &nbsp; &nbsp;- Master of Business Administration (MBA)
                      </option>
                      <option value="Executive Master of Business Administration (EMBA)">
                        &nbsp; &nbsp;- Executive Master of Business
                        Administration (EMBA)
                      </option>
                      <option value="Master of Commerce (MCom)">
                        &nbsp; &nbsp;- Master of Commerce (MCom)
                      </option>
                      <option value="Postgraduate Diplomas (PGD)">
                        &nbsp; &nbsp;- Postgraduate Diplomas (PGD)
                      </option>
                      <option value="">
                        School of Library and Information Science
                      </option>
                      <option value="Bachelor of Library and Information Science (BLib)">
                        &nbsp; &nbsp;- Bachelor of Library and Information
                        Science (BLib)
                      </option>
                      <option value="Master of Library and Information Science (MLib)">
                        &nbsp; &nbsp;- Master of Library and Information Science
                        (MLib)
                      </option>
                      <option value="">School of Science</option>
                      <option value="Diploma in Computer Applications (DCA)">
                        &nbsp; &nbsp;- Diploma in Computer Applications (DCA)
                      </option>
                      <option value="Advance Diploma in Computer Application  (ADCA)">
                        &nbsp; &nbsp;- Advance Diploma in Computer Application
                        (ADCA)
                      </option>
                      <option value="Diploma in Fashion Designing (DFD)">
                        &nbsp; &nbsp;- Diploma in Fashion Designing (DFD)
                      </option>
                      <option value="Diploma in Interior Designing (DID)">
                        &nbsp; &nbsp;- Diploma in Interior Designing (DID)
                      </option>
                      <option value="Bachelor of Science (BSc)">
                        &nbsp; &nbsp;- Bachelor of Science (BSc)
                      </option>
                      <option value="Bachelor of Science (Computer Science) (BSc-CS)">
                        &nbsp; &nbsp;- Bachelor of Science (Computer Science)
                        (BSc-CS)
                      </option>
                      <option value="Bachelor of Science (Computer Science &amp; Information Technology) (B.Sc-CS &amp; IT)">
                        &nbsp; &nbsp;- Bachelor of Science (Computer Science
                        &amp; Information Technology) (B.Sc-CS &amp; IT)
                      </option>
                      <option value="Bachelor of Science (Information Technology) ">
                        &nbsp; &nbsp;- Bachelor of Science (Information
                        Technology)
                      </option>
                      <option value="Bachelor of Science (Fashion Designing) (B.Sc-FD)">
                        &nbsp; &nbsp;- Bachelor of Science (Fashion Designing)
                        (B.Sc-FD)
                      </option>
                      <option value="Bachelor of Science (Interior Designing) (B.Sc-ID)">
                        &nbsp; &nbsp;- Bachelor of Science (Interior Designing)
                        (B.Sc-ID)
                      </option>
                      <option value="Bachelor of Computer Applications (BCA)">
                        &nbsp; &nbsp;- Bachelor of Computer Applications (BCA)
                      </option>
                      <option value="Master of Science (Computer Science) (MSc-CS)">
                        &nbsp; &nbsp;- Master of Science (Computer Science)
                        (MSc-CS)
                      </option>
                      <option value="Master of Science (Computer Science &amp; Information Science) (MSc-CS &amp; IS)">
                        &nbsp; &nbsp;- Master of Science (Computer Science &amp;
                        Information Science) (MSc-CS &amp; IS)
                      </option>
                      <option value="Master of Science (Information Technology) (MSc-IT)">
                        &nbsp; &nbsp;- Master of Science (Information
                        Technology) (MSc-IT)
                      </option>
                      <option value="Master of Science (Biotechnology) (MSc-BT)">
                        &nbsp; &nbsp;- Master of Science (Biotechnology)
                        (MSc-BT)
                      </option>
                      <option value="Master of Science (Electronics) (MSc-Electronics)">
                        &nbsp; &nbsp;- Master of Science (Electronics)
                        (MSc-Electronics)
                      </option>
                      <option value="Master of Computer Applications (MCA)">
                        &nbsp; &nbsp;- Master of Computer Applications (MCA)
                      </option>
                      <option value="Master of Science (Physics/Mathematics/Chemistry/Botany/Zoology/Biotechnology)">
                        &nbsp; &nbsp;- Master of Science
                        (Physics/Mathematics/Chemistry/Botany/Zoology/Biotechnology)
                      </option>
                      <option value="Post Graduate Diploma in Computer Application (PGDCA)">
                        &nbsp; &nbsp;- Post Graduate Diploma in Computer
                        Application (PGDCA)
                      </option>
                      <option value="">
                        School of Journalism and Mass Communication
                      </option>
                      <option value="Diploma Journalism and Mass Communication (DJMC)">
                        &nbsp; &nbsp;- Diploma Journalism and Mass Communication
                        (DJMC)
                      </option>
                      <option value="Bachelor of Arts Journalism and Mass communication (BA-JMC)">
                        &nbsp; &nbsp;- Bachelor of Arts Journalism and Mass
                        communication (BA-JMC)
                      </option>
                      <option value="Master of Arts Journalism and Mass Communication (MA-JMC)">
                        &nbsp; &nbsp;- Master of Arts Journalism and Mass
                        Communication (MA-JMC)
                      </option>
                      <option value="">School of Law</option>
                      <option value="Bachelor of Law (L.L.B)">
                        &nbsp; &nbsp;- Bachelor of Law (L.L.B)
                      </option>
                      <option value="Bachelor of Business Administration and Bachelor of Law (B.B.A. L.L.B.)">
                        &nbsp; &nbsp;- Bachelor of Business Administration and
                        Bachelor of Law (B.B.A. L.L.B.)
                      </option>
                      <option value="LLM">&nbsp; &nbsp;- LLM</option>
                      <option value="">
                        School of Engineering and Technology
                      </option>
                      <option value="Diploma in Engineering">
                        &nbsp; &nbsp;- Diploma in Engineering
                      </option>
                      <option value="Bachelor of Technology">
                        &nbsp; &nbsp;- Bachelor of Technology
                      </option>
                      <option value="Master of Technology">
                        &nbsp; &nbsp;- Master of Technology
                      </option>
                      <option value="">School of Yoga and Naturopathy</option>
                      <option value="Diploma in Yoga (DIY)">
                        &nbsp; &nbsp;- Diploma in Yoga (DIY)
                      </option>
                      <option value="Diploma in Naturopathy (DIN)">
                        &nbsp; &nbsp;- Diploma in Naturopathy (DIN)
                      </option>
                      <option value="Bachelor of Yoga (BIY)">
                        &nbsp; &nbsp;- Bachelor of Yoga (BIY)
                      </option>
                      <option value="Post Graduate Diploma in Yoga (PGDY)">
                        &nbsp; &nbsp;- Post Graduate Diploma in Yoga (PGDY)
                      </option>
                      <option value="">Research Programmes</option>
                      <option value="Ph.D.. Two Years">
                        &nbsp; &nbsp;- Ph.D.. Two Years
                      </option>
                      <option value="Ph.D.. Three Years">
                        &nbsp; &nbsp;- Ph.D.. Three Years
                      </option>
                      <option value="Mphil">&nbsp; &nbsp;- Mphil</option>
                      <option value="">Industry Inegrated Programmes</option>
                      <option value="Master of BUSINESS ADMINISTRATION (MBA) (LOGISTICS &amp; SUPPLY CHAIN MANAGEMENT)">
                        &nbsp; &nbsp;- Master of BUSINESS ADMINISTRATION (MBA)
                        (LOGISTICS &amp; SUPPLY CHAIN MANAGEMENT)
                      </option>
                      <option value="Others">Others</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Amount</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      id="amount1"
                      name="amount"
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      id="comment1"
                      name="comment1"
                      placeholder="Enter Comment"
                      rows={3}
                    />
                  </Form.Group>
                </div>

                {/* <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction Type</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Transaction Type"
                    />
                  </Form.Group>
                </div> */}
              </Row>
            </Form>

            <Form id="tnpForm1" method="POST">
              <input
                type="hidden"
                value="51f1c2bf-8654-4215-8ee4-5bf4ef779955"
                name="api_key"
              />
              <input type="hidden" value="" name="hash" />
              <input
                type="hidden"
                value="https://isbmuniversity.edu.in/api/payment_response.php"
                name="return_url"
              />
              <input type="hidden" value="LIVE" name="mode" />
              <input type="hidden" value="" name="order_id" />
              <input type="hidden" value="" name="amount" />
              <input type="hidden" value="INR" name="currency" />
              <input type="hidden" value="Payment Gateway" name="description" />
              <input type="hidden" value="" name="name" />
              <input type="hidden" value="" name="email" />
              <input type="hidden" value="" name="phone" />
              <input type="hidden" value="Lalji Pada" name="address_line_1" />
              <input
                type="hidden"
                value="Kandivali West"
                name="address_line_2"
              />
              <input type="hidden" value="Mumbai" name="city" />
              <input type="hidden" value="Maharashtra" name="state" />
              <input type="hidden" value="400051" name="zip_code" />
              <input type="hidden" value="India" name="country" />
              <input type="hidden" value="" name="udf1" />
              <input type="hidden" value="" name="udf2" />
              <input type="hidden" value="" name="udf3" />
              <input type="hidden" value="" name="udf4" />
              <input type="hidden" value="" name="udf5" />
              <Button
                variant="primary"
                onClick={(e) => getData(e)}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Onlinepayment;
