import React from "react";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Swamy from "../../Images/Faculty/Dr. N Kumar Swamy.webp";
import DESHMUKH from "../../Images/Faculty/Mr. Ram Krishna Deshmukh.webp";
import GOKUL from "../../Images/Faculty/Mr. Gokul Prasad sahu.webp";
import TRILOCHAN from "../../Images/Faculty/Mr. Trilochan Ram Sahu.webp";
import LAXMIKANT from "../../Images/Faculty/Mr. Laxmikant Sinha.webp";
import HEMANT from "../../Images/Faculty/Mr. Hemant Pandey.webp";
import SUNIL from "../../Images/Faculty/Mr. Sunil Kumar Sahu.webp";
import ARUN from "../../Images/Faculty/DR. ARUN KUMAR SINGH.webp";
import VISVANATHAN from "../../Images/Faculty/DR. P. VISVANATHAN.webp";
import POONAM from "../../Images/Faculty/DR. POONAM VERMA.webp";
import KAMESH from "../../Images/Faculty/MR. KAMESH YADAW.webp";

import GARIMA from "../../Images/Faculty/DR. GARIMA DIWAN.webp";
import SANDEEP from "../../Images/Faculty/DR. SANDEEP KUMAR.webp";

import DIAMOND from "../../Images/Faculty/MR. DIAMOND SAHU.webp";
import ASHWANI from "../../Images/Faculty/MR. ASHWANI SAHU.webp";
import PREETAM from "../../Images/Faculty/MR. PREETAM SAHU.webp";

const Faculties = () => {
  const state1 = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <div className="faculties_background py-5">
      <Container className="faculties_container">
        <h2 className="pt-4">Faculties</h2>
        <p>
          Our esteemed faculty members bring a wealth of knowledge and
          real-world experience to our students, helping them achieve their
          academic and professional goals.
        </p>

        <OwlCarousel
          className="owl-theme"
          margin={20}
          nav={true}
          dots={false}
          items={1}
          touchDrag={true}
          responsive={state1.responsive}
        >
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={Swamy} alt="Master of public health course"></img>
              </div>
              <h3 className="faculties_name">DR. N KUMAR SWAMY</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={DESHMUKH}
                  alt="one of the best university in india"
                ></img>
              </div>
              <h3 className="faculties_name">Prof. RAM KRISHNA DESHMUKH</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={GOKUL} alt="Bachelor of commerce b.com"></img>
              </div>
              <h3 className="faculties_name">Prof. GOKUL PRASAD SAHU</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={TRILOCHAN} alt="Master of commerce m.com"></img>
              </div>
              <h3 className="faculties_name">Prof. TRILOCHAN RAM SAHU</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={LAXMIKANT}
                  alt="Bachelor of business administration bba"
                ></img>
              </div>
              <h3 className="faculties_name">Prof. LAXMIKANT SINHA</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={HEMANT} alt="bca colleges in india"></img>
              </div>
              <h3 className="faculties_name">Prof. HEMANT PANDEY</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={SUNIL}
                  alt="one of the best university in india"
                ></img>
              </div>
              <h3 className="faculties_name">Prof. SUNIL KUMAR SAHU</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={ARUN} alt="best college for b.tech"></img>
              </div>
              <h3 className="faculties_name">DR. ARUN KUMAR SINGH</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={VISVANATHAN}
                  alt="Bachelor of Technology b.tech"
                ></img>
              </div>
              <h3 className="faculties_name">DR. P. VISVANATHAN</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={POONAM} alt="mca universities in india"></img>
              </div>
              <h3 className="faculties_name">DR. POONAM VERMA</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={KAMESH} alt="bachelor of education"></img>
              </div>
              <h3 className="faculties_name">Prof. KAMESH YADAW</h3>
              <small className="faculties_profession1">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text1">SCHOOL OF SCIENCE</p>
            </div>
          </div>

          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={GARIMA} alt="career councelling"></img>
              </div>
              <h3 className="faculties_name">DR. GARIMA DIWAN</h3>
              <small className="faculties_profession">
                ASSOCIATE PROFESSOR
              </small>
              <p className="faculties_text">SCHOOL OF ARTS AND HUMANITIES</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={SANDEEP}
                  alt="bachelor of science in computer science b.sc-computer science"
                ></img>
              </div>
              <h3 className="faculties_name">DR. SANDEEP KUMAR</h3>
              <small className="faculties_profession">
                ASSISTANT PROFESSOR, GEOGRAPHY
              </small>
              <p className="faculties_text">SCHOOL OF ARTS AND HUMANITIES</p>
            </div>
          </div>

          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={DIAMOND}
                  alt="diploma in computer application dca"
                ></img>
              </div>
              <h3 className="faculties_name">Prof. DIAMOND SAHU</h3>
              <small className="faculties_profession">
                ASSISTANT PROFESSOR, HINDI
              </small>
              <p className="faculties_text">SCHOOL OF ARTS AND HUMANITIES</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img
                  src={ASHWANI}
                  alt="bachelor of computer applications bca"
                ></img>
              </div>
              <h3 className="faculties_name">Prof. ASHWANI SAHU</h3>
              <small className="faculties_profession">
                ASSISTANT PROFESSOR, SOCIOLOGY
              </small>
              <p className="faculties_text">SCHOOL OF ARTS AND HUMANITIES</p>
            </div>
          </div>
          <div className="item">
            <div className="Instructors__list__item">
              <div className="faculties_img">
                <img src={PREETAM} alt="diploma in interior design"></img>
              </div>
              <h3 className="faculties_name">Prof. PREETAM SAHU</h3>
              <small className="faculties_profession">
                ASSISTANT PROFESSOR, HISTORY
              </small>
              <p className="faculties_text">SCHOOL OF ARTS AND HUMANITIES</p>
            </div>
          </div>
        </OwlCarousel>
      </Container>
    </div>
  );
};

export default Faculties;
