import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Globalskills = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="globalinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Global Skills</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Global Skills</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Global Skills</h2>
          <p >
              Requirement nowadays is of affordable & convenient formal training
              in non-technical skills with certification from "ISBM University -
              Global Skills":
            </p>
            <p >
              In today's environment, technical competence alone is not
              sufficient for career success. Employers are looking for employees
              with non-technical skills such, that they are having edge over
              others and are able to perform better.
            </p>
            <p>
              <b>Employers want:</b>
            </p>
            <ul >
              <li>Employees who are well rounded.</li>
              <li>Employees having non-technical skills.</li>
              <li>
                Employees who have prepared themselves for the job by balancing
                both their technical and non-technical skills
              </li>
            </ul>
            <p >
              <b>
                Non-Technical Skills desired by employers world-wide in their
                prospective employees now are:
              </b>
            </p>
            <ul >
              <li>Oral and written communication</li>
              <li>Team Work</li>
              <li>Business Awareness</li>
              <li>Organization and management skills</li>
              <li>Responsibility and initiative</li>
              <li>Problem Solving</li>
              <li>IT competency</li>
            </ul>
            <p>
              Only bar during training is of language, since deliverance shall
              be in English language. Yes, But English is the modern day
              language of Business and success world-wide. This training will be
              aimed at people wishing to acquire new-age skills to adapt to
              global platforms of their own area of choice, or to develop their
              existing skills, enabling them to increase their earning power.
            </p>
            <p>
              This training shall also equip learner with practical skills which
              are most imperative and desired by companies/employers, in
              addition to their field's technical knowledge requirement.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Globalskills;
