import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const AdmissionSlidebaar = () => {
  const location = useLocation();

  const menuItems = [
    {
      path: "/programmes-and-eligibilities",
      label: "Programmes and Eligibilities",
    },
    {
      path: "/programmes-by-qualification",
      label: "Programmes By Qualification",
    },
    {
      path: "/industry-integrated-programmes",
      label: "Industry Integrated Programmes",
    },
    { path: "/skill-development", label: "Skill Development" },
    { path: "/Research", label: "Research" },
    { path: "/apply-online", label: "Apply Online" },
    { path: "/pay-fees-online", label: "Online Payment" },
    { path: "/downloads", label: "Download and Resources" },
    {
      path: "/payment-procedure-guidelines",
      label: "Payment Procedure Guidelines",
    },
    {
      path: "/admission-procedure-guidelines",
      label: "Admission Procedure Guidelines",
    },
    { path: "/admission-help-desk", label: "Admission Helpdesk" },
    
  ];

  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default AdmissionSlidebaar;
