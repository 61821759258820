// ImageComponent.js
import React from "react";
import { Modal } from "react-bootstrap";

const ImageModal = ({ imageUrl }) => {
  const [showModal, setShowModal] = React.useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <div onClick={openModal}>
        <img
          style={{ width: "100%", height: "250px", cursor: "pointer" }}
          src={imageUrl}
          alt="Media Image"
        />
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Image Preview</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <img style={{ width: "100%" }} src={imageUrl} alt="Media Image" />
        </Modal.Body>
        <Modal.Footer>
          {/* <button className="btn btn-secondary" onClick={closeModal}>
            Close
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
