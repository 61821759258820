import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import vinay from "../../Images/Hon'ble Persons/vinay.webp";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";
const Chancellor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <MetaTags>
        <title>Discover ISBM University's Chancellor: Visionary Leadership </title>
        <meta
          name="description"
          content="Discover the the leadership at ISBM University through the eyes of the Honorable Chancellor. Explore his visionary role in shaping the educational landscape. "
        />
        <link
          rel="canonical"
          href="https://isbmuniversity.edu.in/honorable-chancellor"
        />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Discover ISBM University's Chancellor: Visionary Leadership "
        />
        <meta
          property="og:description"
          content="Discover the the leadership at ISBM University through the eyes of the Honorable Chancellor. Explore his visionary role in shaping the educational landscape."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/honorable-chancellor/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Discover ISBM University's Chancellor: Visionary Leadership"
        />
        <meta
          name="twitter:description"
          content="Discover the the leadership at ISBM University through the eyes of the Honorable Chancellor. Explore his visionary role in shaping the educational landscape."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta name="keywords" content="Leadership Vision , Future of Education , ISBM University" />

      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>From The Chancellor's Desk</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hon'ble Chancellor</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Row>
              <Col lg={4}>
                <div className="honor_div">
                  <img className="img_honor" src={vinay} alt="Hon'ble Chancellor Dr. Vinay M. Agrawal"/>
                </div>
              </Col>
              <Col lg={8}>
                <h2 className="honor_name">Dr. Vinay M. Agrawal</h2>
                <p className="text-justify">
                  Education is an intervention in the modern society where,
                  harmonious development can be achieved by enlightenment of an
                  individual's heart, mind and soul. I am of the opinion that
                  education can transform the attitude, thought process and
                  lifestyle of the people leading to the development of the
                  society Education makes people flexible in thinking rationally
                  and making appropriate decisions. I have always worked towards
                  empowering the mass. Education serves as the tool & technique
                  of empowerment with skilled and socially relevant citizens.
                </p>
                <p className="text-justify">
                  We initiated the benign social order for social reconstruction
                  and social change by establishing various educational
                  establishments as a partner in the process of economic
                  development of the country, ISBM University has assumed the
                  task of human capital development by integrating the basic
                  principles of knowledge, wisdom and humanity both in its
                  policy and practice.
                </p>
                <p className="text-justify">
                  ISBM University stands for humanism, rationality, idea
                  exploration and innovation. The approach of our curriculum
                  focuses on experiential learning ensuring better content
                  delivery and provisions for co-curricular, extra-curricular
                  activities as integral components of the learning process.
                </p>
                <p className="text-justify">
                  At ISBM University we seek self-motivated individuals who
                  believe in making their own methods of learning by availing
                  the opportunities and willing to contribute their best for
                  social welfare.
                </p>
                <p className="text-justify">
                  ISBM University is proud of its state of the art
                  infrastructure, serene campus with aesthetically built
                  facilities. Student's friendly policies create a satisfying
                  academic ambience in the campus. I extend my best wishes to
                  all the aspirants in the campus and assure them that under the
                  mentorship of experienced faculties of ISBM University they
                  will be able to extract competitive advantage towards creating
                  a harmonious, peaceful and rewarding future. Participation in
                  a wide range of academics and other activities will provide
                  them an opportunity to acquire leadership, skills,
                  professional and personal traits.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Chancellor;
