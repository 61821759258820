import React from "react";
import { Col, Row } from "react-bootstrap";
import icon1 from "../../Images/icons/research11_w.webp";
import icon2 from "../../Images/icons/postgrad11_w.webp";
import icon3 from "../../Images/icons/grad11_w.webp";
import icon4 from "../../Images/icons/cert11_w.webp";
import playIcon from "../../Images/icons/icon-play.webp";

const AboutSec = () => {
  return (
    <React.Fragment>
      <Row className="my-3 row row-cols-1 row-cols-md-5">
        <Col className="levelDiv p-3">
          <div className="innerLevel">
            <img
              src={icon1}
              alt=""
              width="50"
              height="50"
              title="hp2-col-4-icon"
            ></img>
            <h6 className="text-blue fw-semibold mt-4">
              Research and Development
            </h6>
          </div>
        </Col>
        <Col className="levelDiv p-3">
          <div className="innerLevel">
            <img
              src={icon2}
              alt=""
              width="50"
              height="50"
              title="hp2-col-4-icon"
            ></img>
            <h6 className="text-blue fw-semibold mt-4">Post Graduation</h6>
          </div>
        </Col>
        <Col className="levelDiv p-3">
          <div className="innerLevel">
            <img
              src={icon3}
              alt=""
              width="50"
              height="50"
              title="hp2-col-4-icon"
            ></img>
            <h6 className="text-blue fw-semibold mt-4">Graduation</h6>
          </div>
        </Col>
        <Col className="levelDiv p-3">
          <div className="innerLevel">
            <img
              src={icon1}
              alt=""
              width="50"
              height="50"
              title="hp2-col-4-icon"
            ></img>
            <h6 className="text-blue fw-semibold mt-4">Diploma</h6>
          </div>
        </Col>
        <Col className="levelDiv p-3">
          <div className="innerLevel">
            <img
              src={icon4}
              alt=""
              width="50"
              height="50"
              title="hp2-col-4-icon"
            ></img>
            <h6 className="text-blue fw-semibold mt-4">Certificate</h6>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-lg-6 col-md-6 col-sm-12 bg-blue p-5">
          <h1 className="text-white text-center fw-bold pb-3">
            Welcome To ISBM University: Learn, Lead, Change
          </h1>
          <p className="about_usisbm">
            ISBM University endeavors to offer services across the student-life
            cycle, supporting the students not only with teaching-learning but
            also with career development, skill enhancement, employment
            opportunities, business incubation, fundraising and mentoring.
          </p>
          <p className="about_usisbm">
            ISBM University is established and approved by the Government of
            Chhattisgarh under the Chhattisgarh Private Universities
            (Establishment and Operation) Act, 2005 (Amendment Act 2016) to
            confer doctorate, degree, diploma, and certificates to students.
          </p>
          <div className="row mt-4">
            <a className="btn-purple p-3 mx-auto" href="/about-us">READ MORE</a>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 aboutSecbg p-5">
          <div className="flex-column justify-content-center d-flex h-100">
            <a
              className="d-flex justify-content-center pb-3"
              href={"https://www.youtube.com/embed/jMTzToBXj0Q"}
            >
              <img src={playIcon} alt="icon-play" width={82} height={82} />
            </a>
            <h2 className="text-white text-center fw-bold pb-2">
              Video Tour in ISBM University
            </h2>
            <p className="text-white text-center">
              Take a tour in ISBM University and you will find the best
              university in the state. The video will take you to every places
              in this university.
            </p>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default AboutSec;
