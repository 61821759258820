import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";

const Research = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="researchinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Research</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Research</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Research</h2>
            <p>
              The Master of Philosophy (M. Phil.) &amp; Doctor of Philosophy
              (Ph.D.) programs will give an opportunity to the students to
              undertake advanced studies in the subject in which he or she has
              already acquired post-graduation.
            </p>

            <ul className="AboutSection__list">
              <li>
                <div className="AboutSection__list__label">
                  These programs are focused on creating free thinkers-
                  individuals who are independent in their thinking, loyalty,
                  and commitment.
                </div>
              </li>
              <li>
                <div className="AboutSection__list__label">
                  The prime focus of the programs offered is to promote the
                  effective integration of technology with state-of-the-art
                  facilities in teaching and research activities..
                </div>
              </li>
              <li>
                <div>
                  The curriculum offered and learning pedagogy focuses on
                  problem-solving and decision making thus creating honed
                  analytical skills coupled with knowledge and application of
                  various emerging trends in the fields of Management &amp;
                  Commerce, Science,Arts and Humanities.
                </div>
              </li>
              <li>
                <div className="AboutSection__list__label">
                  The broad objectives of the research program is to keep in
                  pace with the expanding frontiers of knowledge in conformity
                  with the requirement of mankind.
                </div>
              </li>
              <li>
                <div className="AboutSection__list__label">
                  Creative and productive inquiry shall be the basic concept
                  underlying research work.
                </div>
              </li>
              <li>
                <div className="AboutSection__list__label">
                  The award of Ph.D. Degree shall be in recognition of high
                  academic achievements, independent research and application of
                  knowledge to solve the social, managerial, technical and
                  scientific problems.
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Research;
