import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow";


const Boardadvisor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    

      <Topnav />
      <Menubar />
      <div className="comminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Board of Advisors</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Board of Advisors</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="w-25">Name of Advisors</th>
                  <th className="w-25">Advisors Designation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Mr. Jitendra Nahar</b>
                  </td>
                  <td>
                    <div>
                      <b>Founder Trustee</b>
                    </div>
                    <div>
                      Kruti Institute of Technology and Engineering, Raipur.
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Rajan Chopra</b>
                  </td>
                  <td>
                    <div>
                      <b>Chancellor</b>
                    </div>
                    <div>
                      Chancellor at Mahatma Gandhi University, Meghalaya
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. A.L Agrawal</b>
                  </td>
                  <td>
                    <div>
                      <b>President-World Education Mission</b>
                    </div>
                    <div>Chairman-Arunachal University of Studies</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr. Sameep Shastri </b>
                  </td>
                  <td>
                    <div>
                      <b>Vice-chairman, BRICS CCI</b>
                    </div>
                    <div>
                      Indian Institute of Governance & Leadership, Vice Chairman
                      - BRICS Chamber of Commerce & Industry
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mrs. Sabitha Natraj</b>
                  </td>
                  <td>
                    <div>
                      <b>General Manager</b>
                    </div>
                    <div>Indian Oil Corporation</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Munir Sayyad</b>
                  </td>
                  <td>
                    <div>
                      <b>General Manager and Head of Reliance</b>
                    </div>
                    <div>JIO Quality Assurance Lab, Mumbai</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Adv. Syed Zeeshan</b>
                  </td>
                  <td>
                    <div>
                      <b>Counsel</b>
                    </div>
                    <div>Advocate Chhattisgarh, India</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr.Akhilesh Kumar Bhatt</b>
                  </td>
                  <td>
                    <div>
                      <b>(DY Director Of Edn.)</b>
                    </div>
                    <div>GNCT – Delhi</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr. Aparajeet Mittal </b>
                  </td>
                  <td>
                    <div>
                      <b>Committee Chair - BRICS CCI</b>
                    </div>
                    <div>
                      Managing Director/Partner- Golden Ace Ventures LLP
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Adv. D.G. Naik</b>
                  </td>
                  <td>
                    <div>
                      <b>Senior Counsel</b>
                    </div>
                    <div>High Court, Mumbai</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr. Shakti Swamy</b>
                  </td>
                  <td>
                    <div>
                      <b>Consultant and Coach Bigvacancy.com</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Adv. SK Farhan</b>
                  </td>
                  <td>
                    <div>
                      <b> Senior Counsel</b>
                    </div>
                    <div>District Court, Raipur</div>
                  </td>
                </tr>
              
               
               
                <tr>
                  <td>
                    <b>Dr. Ravish Sigh</b>
                  </td>
                  <td>
                    <div>
                      <b> Director</b>
                    </div>
                    <div> Thakur R Ramnarayan College of Arts & Commerce</div>
                  </td>
                </tr>
             
                <tr>
                  <td>
                    <b>Mr. Anil Mittal</b>
                  </td>
                  <td>
                    <div>
                      <b> Executive Vice President </b>
                    </div>
                    <div> Jindal steel and Power Ltd</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Pradeep Tandon</b>
                  </td>
                  <td>
                    <div>
                      <b>President </b>
                    </div>
                    <div> Jindal steel and Power Ltd</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Sanjay Gupta</b>
                  </td>
                  <td>
                    <div>
                      <b> Chief Human Resource Officer</b>
                    </div>
                    <div> Hira Group</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Rabindra shah</b>
                  </td>
                  <td>
                    <div>
                      <b>Chief Technology Officer </b>
                    </div>
                    <div> Indian Register of Shipping</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Ragish Rohatgi</b>
                  </td>
                  <td>
                    <div>
                      <b> GM HR and Admin </b>
                    </div>
                    <div> D B Power Limited</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Dilip Kumar Mohanty</b>
                  </td>
                  <td>
                    <div>
                      <b>President -HRM </b>
                    </div>
                    <div> Jayaswal Neco Industries Limited</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr NV Sanjeev</b>
                  </td>
                  <td>
                    <div>
                      <b> Managing Director </b>
                    </div>
                    <div>Accenture </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Rishi Upmanyu</b>
                  </td>
                  <td>
                    <div>
                      <b>T Mobile </b>
                    </div>
                    <div>SR Vice President</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Babuji Abraham</b>
                  </td>
                  <td>
                    <div>
                      <b>founder and Chief Technology Officer</b>
                    </div>
                    <div> V Rize Technologies</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Deepak Likhmania</b>
                  </td>
                  <td>
                    <div>
                      <b>Managing Director </b>
                    </div>
                    <div>Interviz Consulting</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Rahul Mishra</b>
                  </td>
                  <td>
                    <div>
                      <b> Managing Director</b>
                    </div>
                    <div> Cialfor labs Pvt Ltd Pune</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Sumeet Malik</b>
                  </td>
                  <td>
                    <div>
                      <b> Founder</b>
                    </div>
                    <div>PEPCoding</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Lakshman Gupta</b>
                  </td>
                  <td>
                    <div>
                      <b> Director-Operations</b>
                    </div>
                    <div>DiracERP Solutions Pvt Limited</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Sandeep Gupta</b>
                  </td>
                  <td>
                    <div>
                      <b>Regional Head</b>
                    </div>
                    <div> TATA Consultancy Services</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Somesh Sharma</b>
                  </td>
                  <td>
                    <div>
                      <b> COO & Founder</b>
                    </div>
                    <div>Augtech NextWealth IT Services Pvt ltd.</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Rakesh Kumar Singh</b>
                  </td>
                  <td>
                    <div>
                      <b> Chief Human Resource Officer</b>
                    </div>
                    <div>PNC Infratech Limited</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Anil Soni</b>
                  </td>
                  <td>
                    <div>
                      <b>Chief Human Resource Officer</b>
                    </div>
                    <div>Ind Synergy Limited </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr D P Singh</b>
                  </td>
                  <td>
                    <div>
                      <b> SR Vice President </b>
                    </div>
                    <div>CITI Corp</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Rajeev Singh</b>
                  </td>
                  <td>
                    <div>
                      <b> Vice President</b>
                    </div>
                    <div>US Bank</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Meghal Agrawal</b>
                  </td>
                  <td>
                    <div>
                      <b> CEO and Founder</b>
                    </div>
                    <div>Code Nicely </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Vinod Pillay</b>
                  </td>
                  <td>
                    <div>
                      <b>President</b>
                    </div>
                    <div>Kerala Samajam</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr G Swamy</b>
                  </td>
                  <td>
                    <div>
                      <b>President</b>
                    </div>
                    <div>Andhra Association</div>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <b>Dr H K Singh</b>
                  </td>
                  <td>
                    <div>
                      <b> HOD Commerce Dept., Ex VC,Central University</b>
                    </div>
                    <div>Banaras Hindu University</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr S S Bhadouria</b>
                  </td>
                  <td>
                    <div>
                      <b> Dean and HOD Commerce Dept.Central University</b>
                    </div>
                    <div>Indira Gandhi Tribal University</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Santosh Biswas</b>
                  </td>
                  <td>
                    <div>
                      <b>Ph.D. (IIT Kharagpur),HOD</b>
                    </div>
                    <div>IIT Bhilai</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Dilip Singh Sisodia</b>
                  </td>
                  <td>
                    <div>
                      <b> Associate Professor</b>
                    </div>
                    <div>NIT Raipur</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Sourabh Choubey</b>
                  </td>
                  <td>
                    <div>
                      <b> CEO</b>
                    </div>
                    <div>iAnubuti IIIT Delhi Foundation GOI</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Mr Sreejit Panicker</b>
                  </td>
                  <td>
                    <div>
                      <b>Training Head</b>
                    </div>
                    <div>Techment Technologies</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Ashish R  Panat</b>
                  </td>
                  <td>
                    <div>
                      <b>Director-S,I ,I and L</b>
                    </div>
                    <div>SNDT Women's University -Mumbai</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. R Anthony Sathish Benadict</b>
                  </td>
                  <td>
                    <div>
                      <b>Professor & Head </b>
                    </div>
                    <div>Govt College of Arts and Commerce</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Vibhuti Jha</b>
                  </td>
                  <td>
                    <div>
                      <b>Vice Chancellor</b>
                    </div>
                    <div>The Global University</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Monica Sethi</b>
                  </td>
                  <td>
                    <div>
                      <b>Vice Chancellor</b>
                    </div>
                    <div>KK Modi University Bhilai</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Ashwini Kumar Sharma</b>
                  </td>
                  <td>
                    <div>
                      <b> Professor CSE Dept.</b>
                    </div>
                    <div>Graphic Era University</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Prof. (Dr.) Atul M Gonsai</b>
                  </td>
                  <td>
                    <div>
                      <b> Professor of Computer Science, Nodal Officer of Shodh (Govt. of Gujarat),</b>
                    </div>
                    <div>Department of Computer Science, Saurashtra University, Rajkot</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Vivek Tiwari</b>
                  </td>
                  <td>
                    <div>
                      <b> Associate Professor, Department of Computer Science and Engineering</b>
                    </div>
                    <div>ABV-Indian InsƟtute of Technology and Management</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr J T Andrews</b>
                  </td>
                  <td>
                    <div>
                      <b> Professor of Applied Physics,Head, Department of Applied Physics and Optoelectronics,</b>
                    </div>
                    <div>Shri G. S. Institute of Technology and Science,Indore </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Pullela SVVSR Kumar</b>
                  </td>
                  <td>
                    <div>
                      <b>Professor in CSE, Dean(Academics)</b>
                    </div>
                    <div>Aditya College of Engineering</div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <b>Dr A B  Marathe </b>
                  </td>
                  <td>
                    <div>
                      <b> Director and Chairperson,NEP </b>
                    </div>
                    <div>Dr A B Marathe</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. A P  Rao  </b>
                  </td>
                  <td>
                    <div>
                      <b>Deputy Director and Professor-Energy and Environment </b>
                    </div>
                    <div>Symbiosis Institute of International Business</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr B K Sthapak </b>
                  </td>
                  <td>
                    <div>
                      <b> Executive Director, Executive Director, Rungta Group of Institutions </b>
                    </div>
                    <div>Governing Body O P Jindal University</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr Chandreshwar Khan </b>
                  </td>
                  <td>
                    <div>
                      <b>Former Sr. Asst. GM (Training)</b>
                    </div>
                    <div>TATA MOTORS,Jamshedpur</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr. Nitin H Deshapnde</b>
                  </td>
                  <td>
                    <div>
                      <b> Director FOM &III</b>
                    </div>
                    <div>Disha Education Society, Raipur </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dr P N Mukherjee</b>
                  </td>
                  <td>
                    <div>
                      <b>Founder Director</b>
                    </div>
                    <div>NMIMS Navi Mumbai Campus </div>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Boardadvisor;
