import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

function ApplySec() {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        setSchools(response.data.data);
        // console.log(response);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <div className="col-lg-6 col-md-6 col-sm-12 aboutSecbg">
          <ul className="schoolList">
            {schools.map((school) => (
              <li key={school.id}>
                <Link to={`/school-details/${school.name}`}>
                  {school.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 bg-purple p-5">
          <div className="p-5">
            <h2 className="fw-bold pb-3">Apply for Admission</h2>
            <p className="">Applications are now open</p>
            <p className="">
              "Be a part of ISBM University to enhance your knowledge, skills
              and prosperous for the better future"
            </p>
            <div className="row mt-4">
              <a className="btn-blue p-3">Apply Now</a>
            </div>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
}

export default ApplySec;
