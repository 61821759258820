import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import banner_1 from "../../Images/Banner/01.webp";
import banner_2 from "../../Images/Banner/02.webp";
import banner_3 from "../../Images/Banner/03.webp";
import banner_5 from "../../Images/Banner/04.webp";
import banner_6 from "../../Images/Banner/05.webp";
import banner_7 from "../../Images/Banner/06.webp";

import mobilebanner1 from "../../Images/Banner/01-m.webp";
import mobilebanner2 from "../../Images/Banner/02-m.webp";
import mobilebanner3 from "../../Images/Banner/03-m.webp";
import mobilebanner5 from "../../Images/Banner/04-m.webp";
import mobilebanner6 from "../../Images/Banner/05-m.webp";
import mobilebanner7 from "../../Images/Banner/06-m.webp";

const Banner = () => {
  return (
    <React.Fragment>
      <Carousel className="abcd desktop">
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_1} alt="The Best in Campus Facilities and Resources" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_2} alt="International Opportunities" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_3} alt="Convocation Ceremony" />
        </Carousel.Item>

        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_5} alt="ISBM university Awarded by Honourable Dignitaries" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_6} alt="ISBM university Awarded by Honourable Dignitaries" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={banner_7} alt="Awards" />
        </Carousel.Item>
      </Carousel>
      <Carousel className="abcd mobiles">
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner1} alt="The Best in Campus Facilities and Resources" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner2} alt="International Opportunities" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner3} alt="Convocation Ceremony" />
        </Carousel.Item>

        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner5} alt="ISBM university Awarded by Honourable Dignitaries" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner6} alt="ISBM university Awarded by Honourable Dignitaries" />
        </Carousel.Item>
        <Carousel.Item interval={3000} data-pause="true">
          <img className="d-block w-100" src={mobilebanner7} alt="Awards" />
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
};

export default Banner;
