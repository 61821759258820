import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Faculties from "./Faculties";
import WorksAt from "./WorksAt";
import LMS from "../../Images/commonimg/lms.webp";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import avd1 from "../../Images/advantages/1.webp";
import avd2 from "../../Images/advantages/2.webp";
import avd3 from "../../Images/advantages/3.webp";
import avd4 from "../../Images/advantages/4.webp";
import avd5 from "../../Images/advantages/5.webp";

import w1 from "../../Images/icons/careerCounselling.webp";
import w2 from "../../Images/icons/jobPlacement.webp";
import w3 from "../../Images/icons/interviewPreparation.webp";
import w4 from "../../Images/icons/resumeFeedback.webp";

import campus from "../../Images/icons/university.webp";
import hostel from "../../Images/icons/hostel.webp";
import book from "../../Images/icons/book.webp";
import technology from "../../Images/icons/technology.webp";
import transportation from "../../Images/icons/transportation.webp";
import game from "../../Images/icons/game.webp";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 50) : text}
      <button onClick={toggleReadMore} className="readMore">
        {isReadMore ? "  ... read more" : "   ... show less"}
      </button>
    </p>
  );
};

const Allinone = () => {
  return (
    <div>
      <section className="facilities">
        <div className="container">
          <h2 className="fw-bold">Campus Amenities</h2>
          <p>
            Campus amenities are an essential aspect of university life as they
            provide a comfortable and conducive environment for students to
            learn, grow and thrive.
          </p>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={campus} />
                <h4 className="facilitiesh4">
                  Discover Campus Life: Explore, Connect, Succeed
                </h4>
              </div>

              <ReadMore>
                The campus of ISBM University is designed to provide a modern
                and comfortable learning environment for students. The buildings
                are equipped with state-of-the-art facilities such as spacious
                classrooms, well-equipped laboratories, a modern library, sports
                facilities, and residential facilities for students.
              </ReadMore>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={hostel} />
                <h4 className="facilitiesh4">
                  Experience Comfort and Community at Our Hostel
                </h4>
              </div>
              <ReadMore>
                The facility is designed to provide a safe, comfortable and
                convenient living environment for students who come from outside
                the city or country. University hostels offer a range of
                services and amenities for students, such as 24-hour security,
                internet access, and recreational activities. Hostel facility
                from a university is designed to provide a supportive and
                inclusive community for students as they pursue their academic
                goals, while also helping them develop important life skills and
                relationships that can last a lifetime.
              </ReadMore>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={book} />
                <h4 className="facilitiesh4">
                  Discover the World Through Books
                </h4>
              </div>
              <ReadMore>
                The library's physical space is designed to create a peaceful
                and conducive learning environment. The library has reading
                rooms, computer labs, and group discussion areas. The reading
                rooms are equipped with comfortable seating and ample study
                space for individual reading and research. The computer labs
                provide access to computers and internet connectivity for
                research purposes.
              </ReadMore>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={technology} />
                <h4 className="facilitiesh4">
                  Get Connected to the Technology
                </h4>
              </div>
              <ReadMore>
                The Computer Labs at ISBM University are modern and
                well-equipped facilities that provide students with access to
                the latest hardware and software technologies. The labs are
                designed to support a wide range of academic programmes and
                research initiatives, including computer science, engineering,
                business, and other related disciplines. The computer labs are
                staffed by experienced technicians who provide support to
                students, faculty, and staff. They are available to assist with
                software installation, hardware troubleshooting, and other
                technical issues.
              </ReadMore>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={transportation} />
                <h4 className="facilitiesh4">
                  Connecting People Through Efficient Transportation
                </h4>
              </div>
              <ReadMore>
                The library's physical space is designed to create a peaceful
                and conducive learning environment. The library has reading
                rooms, computer labs, and group discussion areas. The reading
                rooms are equipped with comfortable seating and ample study
                space for individual reading and research. The computer labs
                provide access to computers and internet connectivity for
                research purposes.
              </ReadMore>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <img className="amenitiesImg" src={game} />
                <h4 className="facilitiesh4">Get Your Game On</h4>
              </div>
              <ReadMore>
                A sports facility at ISBM University is a dedicated space where
                students, faculty, and staff can engage in various physical
                activities and sports. The facility is equipped with modern
                amenities and equipment to cater to the needs of individuals
                with diverse interests in sports. The facility is designed to
                accommodate both indoor and outdoor sports activities, making it
                convenient for users to engage in their preferred sports
                irrespective of the weather conditions.
              </ReadMore>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blue">
        <div className="container">
          <Row className="p-5">
            <div className="col-lg-6">
              <h2 className="text-white mb-4 fw-bold">
                Transforming Education Through the Power of Technology
              </h2>
              <div>
                Our Learning Platform is a dynamic space where learners can
                access a variety of resources and tools to enhance their
                knowledge and skills. It provides a personalized and interactive
                learning environment that encourages exploration, collaboration,
                and critical thinking. With our Learning Platform, learners can
                take control of their learning experience and engage in
                activities that suit their learning style and pace.
              </div>
            </div>
            <div className="col-lg-6">
              <img src={LMS} alt="LMS"></img>
            </div>
          </Row>
        </div>
      </section>
      <section className="facilities">
        <Container className="fawsqwas">
          <h2 className="fw-bold mb-4">What Sets ISBM University Apart</h2>
          <Col>
            <Col className="bg-blue col p-4 mb-3">
              <div className="row mb-3 mx-0 pb-1 border-bottom">
                <span className="awardlogo2"></span>
                <h4 className="tab-title">Innovative Pedagogy</h4>
              </div>
              <div className="row">
                <Col lg={4}>
                  <img
                    className="w-100"
                    src={avd1}
                    alt="best bba colleges in india"
                  ></img>
                </Col>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Project-based learning</p>
                  <p className="advPara">
                    The university uses project-based learning to encourage
                    students to work collaboratively and apply their learning to
                    real-world problems.
                  </p>
                  <p className="adv">Blended Learning</p>
                  <p className="advPara">
                    It combines traditional classroom teaching with online
                    learning to create a personalized learning experience.
                  </p>
                  <p className="adv">Collaborative Learning</p>
                  <p className="advPara">
                    It involves students working in groups to solve problems and
                    share knowledge.
                  </p>
                </Col>
              </div>
            </Col>
            <Col className="bg-blue col p-4 mb-3">
              <div className="row mb-3 mx-0 pb-1 border-bottom">
                <span className="awardlogo2"></span>
                <h4 className="tab-title">Industry-focused Curriculum</h4>
              </div>
              <div className="d-flex flex-row-reverse">
                <Col lg={4}>
                  <img
                    className="w-100"
                    src={avd2}
                    alt="best bba colleges in india"
                  ></img>
                </Col>
                <Col lg={8} className="accordionPadding">
                  <p className="advPara">
                    Industry-focused curriculum refers to an educational programme
                    that is specifically designed to address the needs and
                    demands of the industry. It is a curriculum that is tailored
                    to equip students with the skills and knowledge needed to
                    succeed in a particular industry, whether that be
                    technology, healthcare, finance, or any other field.
                  </p>
                </Col>
              </div>
            </Col>
            <Col className="bg-blue col p-4 mb-3">
              <div className="row mb-3 mx-0 pb-1 border-bottom">
                <span className="awardlogo2"></span>
                <h4 className="tab-title">Comprehensive Academic Assistance</h4>
              </div>
              <div className="row">
                <Col lg={4}>
                  <img
                    className="w-100"
                    src={avd3}
                    alt="best bba colleges in india"
                  ></img>
                </Col>
                <Col lg={8} className="accordionPadding">
                  <p className="advPara">
                    Comprehensive Academic Assistance refers to the variety of
                    programmes, services, and resources that educational
                    institutions provide to support' students academic success.
                    These programmes aim to help students achieve their academic
                    goals, overcome learning obstacles, and develop essential
                    academic skills. Comprehensive Academic Assistance includes
                    tutoring services, academic counseling, study skills
                    workshops, academic support centers, and time management
                    strategies.
                  </p>
                </Col>
              </div>
            </Col>
            <Col className="bg-blue col p-4 mb-3">
              <div className="row mb-3 mx-0 pb-1 border-bottom">
                <span className="awardlogo2"></span>
                <h4 className="tab-title">Employment Opportunities</h4>
              </div>
              <div className="d-flex flex-row-reverse">
                <Col lg={4}>
                  <img
                    className="w-100"
                    src={avd4}
                    alt="best bba colleges in india"
                  ></img>
                </Col>
                <Col lg={8} className="accordionPadding">
                  <p className="advPara">
                    ISBM University also offers placement services to its
                    students. The university has a dedicated placement cell that
                    works with various companies and organizations to provide
                    job opportunities to its students.
                  </p>
                  <p className="advPara">
                    The placement cell at ISBM University conducts various
                    activities and programmes to help students prepare for their
                    careers, such as resume writing workshops, mock interviews,
                    and career counseling sessions. The university also invites
                    companies to campus for placement drives and job fairs,
                    where students can interact with recruiters and learn about
                    job opportunities.
                  </p>
                </Col>
              </div>
            </Col>
            <Col className="bg-blue col p-4 mb-3">
              <div className="row mb-3 mx-0 pb-1 border-bottom">
                <span className="awardlogo2"></span>
                <h4 className="tab-title">Experienced Faculty</h4>
              </div>
              <div className="row">
                <Col lg={4}>
                  <img
                    className="w-100"
                    src={avd5}
                    alt="best bba colleges in india"
                  ></img>
                </Col>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">In-depth knowledge</p>
                  <p className="advPara">
                    Experienced faculty members possess a deep understanding of
                    their subject matter and are able to provide students with a
                    comprehensive understanding of the topic.
                  </p>
                  <p className="advPara">
                    Expert guidance: Experienced faculty members can provide
                    expert guidance and mentoring to students, helping them to
                    develop their skills and talents and achieve their academic
                    and career goals.
                  </p>
                </Col>
              </div>
            </Col>
          </Col>
        </Container>
      </section>
      <section className="bg-blue py-5">
        <div className="container">
          <h2 className="text-white">Career Services</h2>
          <p>
            ISBM University provides career services to help students make
            informed career choices and enhance their employability skills.
          </p>
          <div className="row">
            <Col>
              <div className="careerRow p-3">
                <div className="career">
                  
                  <img className="itemImg" src={w1} alt="Image"></img>
                </div>
                <h4 className="text-blue text-center fw-bold">Career counseling</h4>
                <p>
                  Career services can provide one-on-one career counseling to
                  help individuals identify their strengths, interests, and
                  values and guide them in making informed career decisions.
                </p>
              </div>
            </Col>
            <Col>
              <div className="careerRow p-3">
                <div className="career">
                  
                  <img className="itemImg" src={w2} alt="Image"></img>
                </div>
                <h4 className="text-blue text-center fw-bold">Job search assistance</h4>
                <p>
                  Job search assistance through job postings, job fairs, and
                  networking events to help individuals find job opportunities
                  that match their skills and interests.
                </p>
              </div>
            </Col>
            <Col>
              <div className="careerRow p-3">
                <div className="career">
                  
                  <img className="itemImg" src={w3} alt="Image"></img>
                </div>
                <h4 className="text-blue text-center fw-bold">Interview preparation</h4>
                <p>
                  Interview preparation services to help individuals prepare for
                  job interviews, including mock interviews, interview tips, and
                  guidance on how to answer common interview questions.
                </p>
              </div>
            </Col>
            <Col>
              <div className="careerRow p-3">
                <div className="career">
                  
                  <img className="itemImg" src={w4} alt="Image"></img>
                </div>
                <h4 className="text-blue text-center fw-bold">Career development workshops</h4>
                <p>
                  Career development workshops and seminars on topics such as
                  job search strategies, networking, personal branding, and
                  professional development to help individuals enhance their
                  skills and advance their careers.
                </p>
              </div>
            </Col>
          </div>
        </div>
      </section>
      <Faculties />
      <section className="bg-blue py-5">
        <div className="container">
          <h2 className="text-white">Our Learners are Employed at</h2>
          <p>
            Our learners are employed in a variety of fields and industries,
            each bringing their unique skills and experiences to their work.
          </p>
          <WorksAt />
        </div>
      </section>
      <section className="UpgradSupport page-section py-5">
        <div className="container">
          <h5>For queries, feedback & assistance</h5>
          <h1>ISBMU Learner Support</h1>
          <h4>(We are available 24*7)</h4>
          <div className="row">
            <div className="col-lg-6">
              <h3>Call us at</h3>
              <button>
                <a href="tel:+91 9373199999" className="text-white">
                  <i className="fa fa-phone" aria-hidden="true"></i> +91
                  9373199999
                </a>
              </button>
            </div>
            <div className="col-lg-6">
              <h3>Email us at</h3>
              <button>
                <a
                  href="mailto:info@isbmuniversity.edu.in"
                  className="text-white"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  info@isbmuniversity.edu.in
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Allinone;
