import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import rec1 from "../../Images/rec/11.webp";
import rec2 from "../../Images/rec/12.webp";
import rec5 from "../../Images/rec/15.webp";
import rec6 from "../../Images/rec/16.webp";
import rec7 from "../../Images/rec/17.webp";
import rec8 from "../../Images/rec/18.webp";
import rec9 from "../../Images/rec/19.webp";
import rec10 from "../../Images/rec/nsdc.webp";
import AICTE from "../../Images/docimg/aicte.pdf";
import UGC from "../../Images/PDF/UGC.pdf";
import NOC from "../../Images/Ordinances andStatute/State Government NOC University Establishment.pdf";
import corporate1 from "../../Images/rec/assocam_img.webp";
import corporate2 from "../../Images/rec/cii_img.webp";
import corporate3 from "../../Images/rec/phd_img.webp";
import international1 from "../../Images/rec/iacc_img.webp";
import international2 from "../../Images/rec/eu_img.webp";
import international3 from "../../Images/rec/unglobal.webp";
import international4 from "../../Images/rec/latestpost.webp";
import international5 from "../../Images/rec/UNSDSN.webp";
import national1 from "../../Images/rec/ISTE.webp";
import national2 from "../../Images/rec/ISTD.webp";
import national3 from "../../Images/rec/bma_img.webp";
import national4 from "../../Images/rec/aims_img.webp";
import national5 from "../../Images/rec/CEGR.webp";
import national6 from "../../Images/rec/sri-aurobindo.webp";
import degree1 from "../../Images/rec/sambalpur-university.webp";
import quality1 from "../../Images/rec/ISO_EGAC.webp";
import quality2 from "../../Images/rec/globally_img.webp";
import cipp from "../../Images/logo/cipp_img.webp";

import Std5 from "./modalpop/std5";
import Std6 from "./modalpop/std6";
import Std7 from "./modalpop/std7";
import Std8 from "./modalpop/std8";
import Cor1 from "./modalpop/cor1";
import Cor2 from "./modalpop/cor2";
import Cor3 from "./modalpop/cor3";
import Int1 from "./modalpop/int1";
import Int2 from "./modalpop/int2";
import Int3 from "./modalpop/int3";
import Int4 from "./modalpop/int4";
import Int5 from "./modalpop/int5";
import Int6 from "./modalpop/int6";
import Qua1 from "./modalpop/qua1";
import Qua2 from "./modalpop/qua2";
import Deg1 from "./modalpop/deg1";
import Nat1 from "./modalpop/nat1";
import Nat2 from "./modalpop/nat2";
import Nat3 from "./modalpop/nat3";
import Nat4 from "./modalpop/nat4";
import Nat5 from "./modalpop/nat5";
import EnquiryNow from "../common/EnquiryNow";

const option = {
  responsive: {
    0: {
      items: 2,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};



const Recognisation = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [modalShow5, setModalShow5] = React.useState(false);
  const [modalShow6, setModalShow6] = React.useState(false);
  const [modalShow7, setModalShow7] = React.useState(false);
  const [modalShow8, setModalShow8] = React.useState(false);
  const [modalShow9, setModalShow9] = React.useState(false);
  const [modalShow10, setModalShow10] = React.useState(false);
  const [modalShow11, setModalShow11] = React.useState(false);
  const [modalShow12, setModalShow12] = React.useState(false);
  const [modalShow13, setModalShow13] = React.useState(false);
  const [modalShow14, setModalShow14] = React.useState(false);
  const [modalShow15, setModalShow15] = React.useState(false);
  const [modalShow16, setModalShow16] = React.useState(false);
  const [modalShow17, setModalShow17] = React.useState(false);
  const [modalShow18, setModalShow18] = React.useState(false);
  const [modalShow19, setModalShow19] = React.useState(false);
  const [modalShow20, setModalShow20] = React.useState(false);
  const [modalShow21, setModalShow21] = React.useState(false);
  const [modalShow22, setModalShow22] = React.useState(false);

  return (
    <>
      <Topnav />
      <Menubar /> 
      <div className="statutesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Recognitions and Approvals</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Recognitions and Approvals</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mt-3">
                <Accordion.Header> Statutory Approvals </Accordion.Header>
                <Accordion.Body>
                  <Row className="modallss">
                    <div className="item col-lg-4 mb-3">
                      <a
                        href={NOC}
                        target={"/"}
                      >
                        <img src={rec1} onClick={() => setModalShow(true)} alt="State Government NOC University Establishment"/>
                      </a>
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <a
                        href={UGC}
                        target={"/"}
                      >
                        <img src={rec2} alt="University Grants Commission"/>
                      </a>
                    </div>

                    <div className="item col-lg-4 mb-3">
                      <a
                        href={AICTE}
                        target={"/"}
                      >
                        <img src={rec5} onClick={() => setModalShow(true)} alt="All India Council for Technical Education"/>
                      </a>
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={rec6} onClick={() => setModalShow2(true)} alt="Chhattisgarh Private Universities Regulatory Commission"/>
                      <Std5
                        show={modalShow2}
                        onHide={() => setModalShow2(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={rec7} onClick={() => setModalShow3(true)} alt="Bar Council of India"/>
                      <Std6
                        show={modalShow3}
                        onHide={() => setModalShow3(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={rec8} onClick={() => setModalShow4(true)} alt="Association of Indian Universities"/>
                      <Std7
                        show={modalShow4}
                        onHide={() => setModalShow4(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={rec9} onClick={() => setModalShow5(true)} alt="Pharmacy Council of India"/>
                      <Std8
                        show={modalShow5}
                        onHide={() => setModalShow5(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={rec10} alt="National Skill Development Corporation"/>
                     
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-3">
                <Accordion.Header>
                  
                  Corporate Memberships & Recognitions
                </Accordion.Header>
                <Accordion.Body>
                <Row>
                    <Col>
                      <img
                        src={corporate1}
                        onClick={() => setModalShow6(true)}
                        className="corporate-img"
                        alt="ASSOCHAM India"
                      ></img>
                    </Col>
                    <Cor1
                      show={modalShow6}
                      onHide={() => setModalShow6(false)}
                    />
                    <Col>
                      <img
                        src={corporate2}
                        onClick={() => setModalShow7(true)}
                        className="corporate-img"
                        alt="Confederation of Indian Industry"
                      ></img>
                    </Col>
                    <Cor2
                      show={modalShow7}
                      onHide={() => setModalShow7(false)}
                    />
                    <Col>
                      <img
                        src={corporate3}
                        onClick={() => setModalShow8(true)}
                        className="corporate-img"
                        alt="PHD Chamber of Commerce and Industry"
                      ></img>
                    </Col>
                    <Cor3
                      show={modalShow8}
                      onHide={() => setModalShow8(false)}
                    />
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mt-3">
                <Accordion.Header>
                  
                  International Associations & Memberships
                </Accordion.Header>
                <Accordion.Body>
                <Row className="modallss">
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={international1}
                        onClick={() => setModalShow9(true)}
                        alt="Indo-American Chamber of Commerce"
                      />
                      <Int1
                        show={modalShow9}
                        onHide={() => setModalShow9(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={international2}
                        onClick={() => setModalShow10(true)}
                        alt="The Council of EU Chambers of Commerce in India"
                      />
                      <Int2
                        show={modalShow10}
                        onHide={() => setModalShow10(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={cipp} onClick={() => setModalShow11(true)} alt="The Chartered Institute of Payroll Professionals"/>
                      <Int3
                        show={modalShow11}
                        onHide={() => setModalShow11(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={international3}
                        onClick={() => setModalShow14(true)}
                        alt="UN Global Compact Network India"
                      />
                      <Int4
                        show={modalShow14}
                        onHide={() => setModalShow14(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={international4}
                        onClick={() => setModalShow12(true)}
                        alt="United Nations Academic Impact"
                      />
                      <Int5
                        show={modalShow12}
                        onHide={() => setModalShow12(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={international5}
                        onClick={() => setModalShow13(true)}
                        alt="Sustainable Development Solutions Network"
                      />
                      <Int6
                        show={modalShow13}
                        onHide={() => setModalShow13(false)}
                      />
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mt-3">
                <Accordion.Header>
                  
                  National Associations & Memberships
                </Accordion.Header>
                <Accordion.Body>
                <Row className="modallss">
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={national1}
                        onClick={() => setModalShow18(true)}
                        alt="Indian Society for Technical Education (ISTE)"
                      />
                      <Nat1
                        show={modalShow18}
                        onHide={() => setModalShow18(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={national2}
                        onClick={() => setModalShow19(true)}
                        alt="ndian Society for Training & Development (ISTD)"
                      />
                      <Nat2
                        show={modalShow19}
                        onHide={() => setModalShow19(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={national3}
                        onClick={() => setModalShow20(true)}
                        alt="Bombay Management Association (BMA)"
                      />
                      <Nat3
                        show={modalShow20}
                        onHide={() => setModalShow20(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={national4}
                        onClick={() => setModalShow21(true)}
                        alt="AIMS International"
                      />
                      <Nat4
                        show={modalShow21}
                        onHide={() => setModalShow21(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img
                        src={national5}
                        onClick={() => setModalShow22(true)}
                        alt="Center for Education Growth & Research"
                      />
                      <Nat5
                        show={modalShow22}
                        onHide={() => setModalShow22(false)}
                      />
                    </div>
                    <div className="item col-lg-4 mb-3">
                      <img src={national6} alt="SRI Aurobindo Society"/>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mt-3">
                <Accordion.Header> Degree Equivalence</Accordion.Header>
                <Accordion.Body>
                <Row>
                    <Col lg={5}>
                      
                      <img
                        src={degree1}
                        onClick={() => setModalShow17(true)}
                        alt="SAMBALPUR UNIVERSITY"
                      ></img>
                    </Col>
                    <Deg1
                      show={modalShow17}
                      onHide={() => setModalShow17(false)}
                    />
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="mt-3">
                <Accordion.Header>
                  
                  Quality Assurance & Compliances
                </Accordion.Header>
                <Accordion.Body>
                <Row>
                    <img
                      src={quality1}
                      onClick={() => setModalShow16(true)}
                      style={{ width: "30%" }}
                      alt="International Organization for Standardization (ISO)"
                    ></img>
                    <Qua1
                      show={modalShow16}
                      onHide={() => setModalShow16(false)}
                    />
                    <img
                      src={quality2}
                      onClick={() => setModalShow15(true)}
                      style={{ width: "30%" }}
                      alt="GA Mark for “Quality Education”"
                    ></img>
                    <Qua2
                      show={modalShow15}
                      onHide={() => setModalShow15(false)}
                    />
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Recognisation;
