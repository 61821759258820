import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import media15 from "../../Images/media/news.webp";
import EnquiryNow from "../common/EnquiryNow";
 

const  Workat  = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="recruitersinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Work at ISBM University</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Work at ISBM University</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Work at ISBM University</h2>
          <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">SN</th>
                  <th scope="col">Name of Post</th>
                  <th scope="col">School Discipline</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>
                    Professor/
                    <br /> Associate Professor/
                    <br /> Assistant Professor/
                    <br />
                    <br />
                    Qualification & Experience
                    <br /> As per UGC/BCLPCU AICTE norms.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    Dean (Academic)/
                  </td>
                  <td>
                   
                      History, Education, English, Hindi, Political Science,
                      Sanskrit, Economics, Geography, Sociology, Social work,
                      Film Making, Yoga Science, Nutrition, Journalism & Mass
                      communication, Public Administration, Psychology,
                      Philosophy. Chhattisgarhi, Zoology, Chemistry, Physics,
                      Botany, Mathematics, Biotechnology, Microbiology,
                      Biochemistry, Clinical Research, Public Health Forestry
                      Fashion Designing, Interior Designing. Commerce &
                      Management Law, Library & Information Science, Engineering
                      (Mining, Electrical, Civil, Mechanical, Computer Science
                      Engineering), Pharmacy, Information Technology, Event
                      Management, Disaster Management, Physiotherapy,
                      Sericulture, Data Science, Defence, Studies and Forensic
                      Science
                      <br />
                    <br />
                   Dean Research, DSW As per UGC norms.
                  </td>
                </tr>
                <tr>
                  <td scope="row">2</td>
                  <td>Non Academic</td>
                  <td>
                    Controller of Examinations, Training and Placement Officer,
                    Warden (for Girls and Boys Hostel) Sports Officer, Music
                    Teacher, Gym Trainer, System Administrator, Software
                    Developer, Marketing Manager and Marketing Executives
                  </td>

            
                </tr>
              </tbody>
            </table>
            <Col lg={6}>
              <div className="mt-4">
                <img className="w-100" src={media15} alt="Recruitment Notice"></img>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Workat ;

