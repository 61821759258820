import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import { MetaTags } from "react-meta-tags";
import EnquiryNow from "../common/EnquiryNow";

const AcademicPhilosophy = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <MetaTags>
        <title>
          ISBM University Academic Philosophy - Shaping Future Leaders
        </title>
        <meta name="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development." />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University Academic Philosophy - Shaping Future Leaders"
        />
        <meta
          property="og:description"
          content="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development."
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University Academic Philosophy - Shaping Future Leaders"
        />
        <meta
          name="twitter:description"
          content="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Academic Philosophy, Education Excellence, ISBM University Values"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="academecinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Academic Philosophy</h1>
          <div  onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Academic Philosophy</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Academic Philosophy</h2>
          <p className="fs-5 fst-italic">
              ISBM University aims to help students realize dreams through an
              educational pedagogy which focuses better on connecting with them
              as people, inside and outside the classroom.
            </p>
            <p >
              With many experiences enriched over past quite many years, our
              tools of teaching have changed considerably, but the heart of what
              we do has just remained constant. The relationships that our
              faculty members look forward to build with the learners take ISBM
              University education far beyond merely imparting knowledge and
              preparing job-seekers. Our faculties surely experts in their
              respective fields, but seek to produce graduates who will aspire
              to have more than a successful career. In partnership with ISBM
              University's academic, social life, and support staff, they aim to
              graduate thought-leaders who will make an impact for betterment,
              both at their local community and in the larger world. At ISBM
              University, learners better be ready to get challenged
              intellectually by academic programs that expects the best of their
              efforts and energies. Learners also become part of a close
              university community where people care about each other. According
              to Chancellor Dr. VinayAgrawal, ISBM University's students can
              make a difference in the University itself much before they go out
              making difference in the real world.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AcademicPhilosophy;
