import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Accordion,
  Form,
  Button,
} from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";

const Applyonline2 = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="applyinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Apply Online</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Online Application</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <Form
              action="https://pay.basispay.in/v2/paymentrequest"
              method="post"
            >
              <Accordion defaultActiveKey="0" className="applyonline">
                <Accordion.Item eventKey="0" className="fs-5">
                  <Accordion.Header>
                    <b className="fs-5">Student Imperative Details</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5>Application form</h5>
                    <p>
                      This page is an electronic version of the application
                      form. Filling and submitting this form will navigate to an
                      online payment process/page. If you don't wish to do an
                      online payment, you are requested to download the form,
                      fill it and send it to your nearest ISBM University
                      center.
                    </p>

                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Category</Form.Label>
                          <Form.Select defaultValue="...Select Category...">
                            <option>Select Category...</option>
                            <option>Bachelor of arts</option>
                            <option>Bachelor of social work</option>
                            <option>Bachelor of arts</option>
                            <option>Bachelor of commerce</option>
                            <option>Master of commerce</option>
                            <option>Bachelor of business administration</option>
                            <option>Master of arts education</option>
                            <option>Master of arts</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Full Name *(In block Letters)</Form.Label>
                          <Form.Control type="text" placeholder="Full name" />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Address for correspondence*</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Date of birth</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Date of birth"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nationality*</Form.Label>
                          <Form.Control type="text" placeholder="Nationality" />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email*</Form.Label>
                          <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Course</Form.Label>
                          <Form.Select defaultValue="...Select Course...">
                            <option>Select Category...</option>
                            <option>Bachelor of arts</option>
                            <option>Bachelor of social work</option>
                            <option>Bachelor of arts</option>
                            <option>Bachelor of commerce</option>
                            <option>Master of commerce</option>
                            <option>Bachelor of business administration</option>
                            <option>Master of arts education</option>
                            <option>Master of arts</option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            Father's Name *(In block Letters)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Father's name"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Permanent Address</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <Form.Check
                          type="radio"
                          label="female"
                          name="formHorizontalRadios"
                          id="formHorizontalRadios1"
                        />
                        <Form.Check
                          type="radio"
                          label="male"
                          name="formHorizontalRadios"
                          id="formHorizontalRadios1"
                        />
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Telephone*</Form.Label>
                          <Form.Control type="number" placeholder="Mobile" />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="mt-4 fs-5">
                  <Accordion.Header>
                    <b className="fs-5">
                      Academic &amp; Work Experience Details
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Examination taken (Please list in chronological order
                      including examinations with results pending)
                    </p>

                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Degree</Form.Label>
                          <Form.Control type="text" placeholder="Degree" />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Exam Month</Form.Label>
                          <Form.Control type="month" q />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Subjects specialization</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Subjects specialization"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Work Experience</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Work Experience"
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            Name of the Institution / College / School :
                          </Form.Label>
                          <Form.Control type="text" placeholder="College" />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Exam Year:</Form.Label>
                          <Form.Control type="year" />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Results / Grades / Percentage</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Results / Grades / Percentage :"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mt-4 fs-5">
                  <Accordion.Header>
                    <b className="fs-5">Admission Details</b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Counselling Centre*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Counselling Centre"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Counselor Name*</Form.Label>
                          <Form.Control
                            type="text"
                            placholder="Counselor Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Pay Amount*</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Pay Amount"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Comments</Form.Label>
                      <Form.Control as="textarea" rows={2} />
                    </Form.Group>
                    <h6>Declaration by the Candidate</h6>
                    <p>
                      I certify that all information provided in this
                      application proforma is complete and accurate. I agree to
                      familiarize myself with all the rules and regulations of
                      the programme set forth by ISBM University and abide by
                      them. I would uplift the standards and respect the
                      principles of ISBM University as an organization of higher
                      learning.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Button
                variant="primary"
                name="submit"
                type="submit"
                className="mt-4"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Applyonline2;
