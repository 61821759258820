import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import overview1 from "../../Images/commonimg/campus-overview1.webp";
import overview2 from "../../Images/commonimg/campus-overview2.webp";
import campus from "../../Images/commonbanner/ISBMU_Campus.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";

const Overview = () => {
  const [modalShow, setModalShow] = React.useState(false);
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
    return (
      <>
       
        <Topnav />
        <Menubar />
        <div className="overviewinnerbackimage innerbackimage">
          <div className="text_banners_abcds">
            <h1>Overview</h1>
            <div onClick={() => setModalShow(true)}>
              <span>
                <a className="btn btn-primary" href="#" role="button">
                  Apply Now
                </a>
              </span>
            </div>
            <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Overview</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Campusslidebaar />
            <Col lg={9}>
            <h2 className="linkabout123">Overview</h2>
            <p>
              Largest campus amongst private Universities in Chhattisgarh on a
              sprawling campus of the single continuous plot. The lush green
              surroundings provide serene ambiance, conducive to render our
              young minds in creative academic pursuits away from the
              distractions of modern city life. Amenities like multi-specialty
              Hospitals, residenatial facilities are in the nearby vicinity of
              Campus.
            </p>
            <p>
              The sprawling 32-acre campus of ISBM University is nestled in the
              heart of nature. The sylvan surroundings of the campus amidst the
              forest and hills, provide an inspiring learning environment for
              everyone. Blessed with the divinity of Tenganhi Mata (Durga Maa)
              Temple in its neighborhood, the campus is complete with all modern
              facilities and features.
            </p>
            <Row>
              <Col>
                <img
                  src={overview1}
                  alt="Best colleges in india"
                  className="img-fluid"
                  style={{ marginBottom: "20px" }}
                ></img>
              </Col>
              <Col>
                <img
                  src={overview2}
                  alt="Best colleges in india"
                  className="img-fluid"
                  style={{ marginBottom: "20px" }}
                ></img>
              </Col>
            </Row>
            <ul >
              <li>Spacious classrooms</li>
              <li>Student Service Centre</li>
              <li>Library</li>
              <li>
                INTEL: The ISBM University Network for Technology
                Entrepreneurship and Leadership
              </li>
              <li>Bonsai: Startup Incubation Cell</li>
              <li>Infirmary and Medical Care</li>
              <li>Yoga and Meditation hall</li>
              <li>Indoor Sports Complex</li>
              <li> Outdoor Sports</li>
              <li>Student Residences</li>
              <li>Kitchen and Dining Block</li>
              <li>Staff Residences</li>
              <li>Seminar Hall</li>
              <li>Hi-speed Internet</li>
              <li>Safe and Secured campus</li>
            </ul>

            <img src={campus} style={{ marginBottom: "20px" }} alt="One of the best university in India."></img>

            <p>
              ISBM University has excellent laboratory facilities for carrying out
              teaching, research and consultancy activities in various
              disciplines of design, media, and mass communication.
            </p>
            <p>
              The University has state of the art labs that allow the students
              to experiment and bring to practice what they have learned in
              theory.
            </p>

            <p>
              The Laboratory at ISBM University facilitates the progress of
              logical and decisive thinking skills of the students. The
              University has dedicated laboratories for physics, chemistry and
              biology programmes. Each laboratory is well equipped with sufficient
              equipment, apparatus, specimens, and computerized charts as per
              the requirements of different subjects. The laboratories are
              environmental friendly and all the necessary safety measures have
              been taken while designing the laboratory.
            </p>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }


export default Overview;
