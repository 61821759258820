import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const OnlineResources = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <MetaTags>
        <title>Downloads and Online Resources | ISBM University</title>
        <meta name="Access a wealth of educational materials and resources at your fingertips. Explore and download online resources curated by ISBM University for a seamless learning experience." />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Downloads and Online Resources | ISBM University"
        />
        <meta
          property="og:description"
          content="Access a wealth of educational materials and resources at your fingertips. Explore and download online resources curated by ISBM University for a seamless learning experience."
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Downloads and Online Resources | ISBM University"
        />
        <meta
          name="twitter:description"
          content="Access a wealth of educational materials and resources at your fingertips. Explore and download online resources curated by ISBM University for a seamless learning experience."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Best Online Learning Resources, Education Resources for Students, Digital Learning Resources"
        />
      </MetaTags>

      <Topnav />
      <Menubar />
      <div className="downloadsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Downloads and Online Resources</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow  show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Downloads and Online Resources</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Downloads and Online Resources</h2>
          <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>E-Resources</Accordion.Header>
                <Accordion.Body >
                  <p>
                    School Prospectus to get&nbsp;
                    <a href="#">Student Information</a>
                  </p>

                  <p>
                    School Prospectus to get&nbsp;
                    <a
                      href="http://isbmuniversity.online/login"
                      target="_blank"  className="text-dark"
                    >
                      E-
                    </a>
                  </p>

                  <p>
                    School Prospectus to get{" "}
                    <a href="http://www.isbm.online/" target="_blank"  className="text-dark">
                      Live Lectures
                    </a>
                  </p>

                  <p>
                    School Prospectus to get{" "}
                    <a
                      href="http://mediaconsole.pearsoncmg.com/curriculum/intl/India/ISBMA/ISBMA.html"
                      target="_blank" className="text-dark"
                    >
                      Pearson Study Material
                    </a>
                  </p>

                  <p>
                    School Prospectus to get{" "}
                    <a href="http://www.isbmlms.com/" target="_blank"  className="text-dark">
                      Learning Management System
                    </a>
                  </p>

                  <p>
                    School Prospectus to get{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.hit.isbmuniversity"
                      target="_blank"  className="text-dark"
                    >
                      ISBM University Android App
                    </a>
                  </p>
                </Accordion.Body>
              </Accordion.Item >
              <Accordion.Item eventKey="1" className="mt-3">
                <Accordion.Header>Recorded Video Lectures</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4}>
                      <iframe
                        width="100%"
                        height="200"
                        src="https://www.youtube.com/embed/MuFUyrsr7u0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </Col>        
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default OnlineResources;
