import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Recruiters = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="recruitersinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Recruiters</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Recruiters</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Recruiters</h2>
          <p >
              Over the years, several well-known companies of the corporate
              world and other agencies have offered ISBM University for
              recruitment of our professionals. Many of our students have gone
              abroad for higher studies.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Recruiters ;

