import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import w1 from "../../Images/companylogo/1.webp";
import w2 from "../../Images/companylogo/2.webp";
import w3 from "../../Images/companylogo/3.webp";
import w4 from "../../Images/companylogo/4.webp";
import w5 from "../../Images/companylogo/5.webp";
import w6 from "../../Images/companylogo/6.webp";
import w7 from "../../Images/companylogo/7.webp";
import w8 from "../../Images/companylogo/8.webp";
import w9 from "../../Images/companylogo/9.webp";
import w10 from "../../Images/companylogo/10.webp";
import w11 from "../../Images/companylogo/11.webp";
import w12 from "../../Images/companylogo/12.webp";
import w13 from "../../Images/companylogo/13.webp";
import w14 from "../../Images/companylogo/14.webp";
import w15 from "../../Images/companylogo/15.webp";
import w16 from "../../Images/companylogo/16.webp";
import w17 from "../../Images/companylogo/17.webp";
import w18 from "../../Images/companylogo/18.webp";
import w19 from "../../Images/companylogo/19.webp";
import w20 from "../../Images/companylogo/20.webp";
import w21 from "../../Images/companylogo/21.webp";
import w22 from "../../Images/companylogo/22.webp";
import w23 from "../../Images/companylogo/23.webp";
import w24 from "../../Images/companylogo/24.webp";
import w25 from "../../Images/companylogo/25.webp";
import w26 from "../../Images/companylogo/26.webp";
import w27 from "../../Images/companylogo/27.webp";
import w28 from "../../Images/companylogo/28.webp";
import w29 from "../../Images/companylogo/29.webp";
import w30 from "../../Images/companylogo/30.webp";
import w31 from "../../Images/companylogo/31.webp";
import w32 from "../../Images/companylogo/32.webp";
import w33 from "../../Images/companylogo/33.webp";
import w34 from "../../Images/companylogo/34.webp";
import w35 from "../../Images/companylogo/35.webp";
import w36 from "../../Images/companylogo/36.webp";
import w37 from "../../Images/companylogo/37.webp";
import w38 from "../../Images/companylogo/38.webp";
import w39 from "../../Images/companylogo/39.webp";
import w40 from "../../Images/companylogo/40.webp";
import w41 from "../../Images/companylogo/41.webp";
import w42 from "../../Images/companylogo/42.webp";
import w43 from "../../Images/companylogo/43.webp";
import w44 from "../../Images/companylogo/44.webp";
import w45 from "../../Images/companylogo/45.webp";
import w46 from "../../Images/companylogo/46.webp";
import w47 from "../../Images/companylogo/47.webp";
import w48 from "../../Images/companylogo/48.webp";
import w49 from "../../Images/companylogo/49.webp";
import w50 from "../../Images/companylogo/50.webp";

const WorksAt = () => {
    const state1 = {
        responsive: {
          0: {
            items: 2,
          },
          450: {
            items: 2,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 8,
          },
        },
      };
  return (
    <OwlCarousel
      className="owl-emp"
      margin={40}
      autoplay={true}
      autoplaySpeed={1000}
      nav={true}
      slideBy={4}
      dots={true}
      items={1}
      touchDrag={true}
      
      responsive={state1.responsive}
    >
      <div className="item">
        <img className="itemImg" src={w1} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w2} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w3} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w4} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w5} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w6} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w7} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w8} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w9} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w10} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w11} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w12} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w13} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w14} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w15} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w16} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w17} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w18} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w19} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w20} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w21} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w22} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w23} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w24} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w25} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w26} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w27} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w28} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w29} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w30} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w31} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w32} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w33} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w34} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w35} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w36} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w37} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w38} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w39} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w40} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w41} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w42} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w43} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w44} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w45} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w46} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w47} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w48} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w49} alt="Image"></img>
      </div>
      <div className="item">
        <img className="itemImg" src={w50} alt="Image"></img>
      </div>

    </OwlCarousel>
  );
};

export default WorksAt;
