import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Form,Button } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import emailjs from "emailjs-com";
import EnquiryNow from "../common/EnquiryNow";
 

const Contactus  = () => {
    function sendEmail(e) {
        e.preventDefault();
        emailjs
          .sendForm(
            "service_3iwjjzi",
            "template_i6xl0px",
            e.target,
            "SzAIZHsYQqNMsL6wY"
          )
          .then((res) => {
            alert("Thank You Our Team Will Contact You");
            window.location.href = "http://isbmuniversity.edu.in/";
          })
          .catch((err) => console.log(err));
      }
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="antiragginginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Contact Us</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
        </Breadcrumb>
        <Row style={{ justifyContent: "space-around" }}>
          <div className="col-md-3">
            <div className="contactDiv">
              <h6>Contact Details:</h6>
              <p className="mt-2 mb-0">
                <span>
                  <a href="https://api.whatsapp.com/send/?phone=%2B919373199999&text&type=phone_number&app_absent=0">
                    <i className="fa fa-whatsapp"></i>
                  </a>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+919373199999">+91-9373199999</a>
                </span>
              </p>
              <p className="mt-2 mb-0 ms-4">
                <span>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+919373199999">+91-9109333333</a>
                </span>
              </p>
              <p className="mt-2 mb-0 ms-4" >
                <span>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+919373199999" >+91-9109244444</a>
                </span>
              </p>
              <p className="mt-2 mb-0">
                <span>
                  
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:info@isbmuniversity.edu.in">
                    info@isbmuniversity.edu.in
                  </a>
                </span>
              </p>
            </div>
            <div className="contactDiv">
              <h6>Address:</h6>
              <h5>ISBM University Campus</h5>
              <p className="mt-2 mr-2">
                Nawapara (Kosmi), Block & Tehsil- Chhura, Gariyaband,
                Chhattisgarh- 493996
              </p>
            </div>
            <div className="contactDiv">
              <h5>ISBM University City Office</h5>
              <p className="mt-2 mr-2">
                ISBM University, Next to Radiant Way School, GE Road Behind
                Pt.Ravi Shankar Shukla University, Amanaka, Raipur,
                Chhattisgarh- 492010
              </p>
            </div>
          </div>
          <div className="col-md-8">
       
            <div className="wm-student-settings-info">
              <div className="wm-student-dashboard-settings">
                <div className="wm-plane-title">
                  <h1 className="mb-2">Talk To Us Today</h1>
                </div>
                <Form name="myForm" onSubmit={sendEmail}>
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Full Name"
                      className="formcontrol"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      className="formcontrol"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter Mobile Number"
                      className="formcontrol"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comment"
                      placeholder="Enter Comment"
                      rows={3}
                      className="formcontrol"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
           
          </div>
          
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Contactus;
