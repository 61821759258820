import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Tab, Tabs } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import After10th from "./After10th";
import After12th from "./After12th";
import AfterGraduation from "./AfterGraduation";
import AfterPostgraduate from "./AfterPostgraduate";
import EnquiryNow from "../common/EnquiryNow";

const ProgrammesByQualification = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Programmes By Qualification</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Programmes By Qualification</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Programmes By Qualification</h2>
            <p>
              We provide management education with innovative applied research
              and practice to educate business leaders of tomorrow. Our
              graduates make a difference by creating real value for society by
              emphasizing a focus on a holistic multi-disciplinary, and
              multi-cultural approach to learning.
            </p>
            <p>
              Interaction with business leaders and entrepreneurs, industrial
              visits and workshops, prepares the students to be leaders who can
              address the most urgent and challenging problems that the world is
              facing today.
            </p>
            <p>
              Personality enhancement and practical experience through a
              continuous process of industry-academia interface are facilitated,
              thereby making them socially responsible, culturally aware and
              ethically better citizens of today and tomorrow.
            </p>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              className="programmesByQualification"
              id="noanim-tab-example"
            >
              <Tab eventKey="home" title="After 10th Programmes">
                <Row className="tenth">
                  <ul className="inner">
                    <After10th />
                  </ul>
                </Row>
              </Tab>
              <Tab eventKey="profile" title="After 12th Programmes">
                <Row className="tenth">
                  <ul className="inner">
                    <After12th />
                  </ul>
                </Row>
              </Tab>
              <Tab eventKey="Undergraduate" title="After Graduation Programmes">
                <Row className="tenth">
                  <ul className="inner">
                  <AfterGraduation />
                  </ul>
                </Row>
              </Tab>
              <Tab eventKey="Postgraduate" title="After Post-Graduation Programmes">
                <Row className="tenth">
                  <ul className="inner">
                  <AfterPostgraduate />
                  </ul>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgrammesByQualification;
