import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import bhol from "../../Images/Hon'ble Persons/bhol.webp";
import EnquiryNow from "../common/EnquiryNow";


const Registrar = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <>
        <Topnav />
        <Menubar />
        <div className="Visitorinnerbackimage innerbackimage">
          <div className="text_banners_abcds">
            <h1>From Registrar's Desk</h1>
            <div  onClick={() => setModalShow(true)}>
              <span>
                <a className="btn btn-primary" href="#" role="button">
                  Apply Now
                </a>
              </span>
            </div>
            <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Registrar</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Sidebaar />
            <Col lg={9}>
              <Row>
                <Col lg={4}>
                  <div className="honor_div">
                    <img className="img_honor" src={bhol} alt="Registrar Dr. B.P.Bhol"/>
                  </div>
                </Col>
                <Col lg={8}>
                  <h2 className="honor_name">Dr. B.P. Bhol</h2>
                  <p className="text-justify">
                    On behalf of the Administration, faculty, and staff of ISBM
                    University. I am pleased to extend a warm welcome to all of
                    you.
                  </p>
                  <p className="text-justify">
                    Education is a powerful tool that can break down the
                    barriers to development, and higher education equips
                    individuals with the ability to make better choices, gain
                    access to economic stability, and achieve financial
                    security. Our university is dedicated to providing quality
                    education through the use of the latest technology and high
                    academic standards. We have an excellent team of highly
                    qualified, committed, and friendly staff members who are
                    here to support our students and provide them with the best
                    education possible. Our faculty members are also committed
                    to advising and working with students to help them achieve
                    their academic and professional goals. At ISBM University,
                    we are committed to providing our students with improved
                    career opportunities and greater socio-cultural awareness
                    through our comprehensive curricular, co-curricular, and
                    extra-curricular activities.
                  </p>
                  <p className="text-justify">
                    Our ultimate aim is to support our students and
                    professionals in achieving success in their personal and
                    professional lives, this results in increased career
                    prospects and earning potential. Further to enhance our
                    student's career we offer internships that provide practical
                    opportunities for skill acquisition and career development.
                  </p>
                  <p className="text-justify">
                    The University provides affordable courses so that it will
                    benefit most of the students and professionals across the
                    world. ISBM University also welcomes world reputed
                    universities and academic institutions for academic
                    partnership and educational exchange programmes and
                    recognitions.
                  </p>
                  <p className="text-justify">
                    At ISBM University, we strive to empower our students with
                    the knowledge, skills, and confidence to achieve their full
                    potential and succeed in their personal and professional
                    lives. We hope that your experience at ISBM University will
                    be both rewarding and fulfilling, and that it will leave a
                    lasting impression on your mind.
                  </p>
                  <p className="text-justify">
                    I take this opportunity to invite the aspirants to the
                    University for a better learning experience.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }


export default Registrar;
