import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useLocation } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { MetaTags } from "react-meta-tags";
import Footer from "../common/footer";
import EnquiryNow from "../common/EnquiryNow";
import Allinone from "../common/Allinone";
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";
import Brochure from "../../Images/Brochure/ISBM_PROSPECTUS.pdf";
import Prospectus from "../../Images/Brochure/Yoga & Naturopathy.pdf";

const CourseDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const { courseName } = useParams();
  const [course, setCourse] = useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [courseList, setCourseList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/courses/${courseName}`
        );
        const courseData = response.data.data[0];
        setCourse(courseData);
        setSchoolName(courseData.school_name);
      } catch (error) {
        setError("Error fetching course details");
        console.error("Error fetching course details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [courseName]);

  useEffect(() => {
    if (schoolName) {
      const fetchCourseList = async () => {
        try {
          const response = await axios.get(
            `https://isbmuniversity.edu.in/admin-portal/coursesList/${schoolName}`
          );
          setCourseList(response.data.data);
        } catch (error) {
          console.error("Error fetching courseList:", error);
        }
      };

      fetchCourseList();
    }
  }, [schoolName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {course && (
        <>
          <MetaTags>
            <title>{course.metatitle}</title>
            <meta name="description" content={course.metadesc} />
            <link
              rel="canonical"
              href={`https://isbmuniversity.edu.in/course-details/${course.name}`}
            />
            <meta
              name="SearchEngines"
              content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
              DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
            />
            <meta name="Robots" content="INDEX, FOLLOW" />
            <meta
              name="Author"
              content="ISBM University, isbmuniversity.edu.in/"
            />
            <meta name="Robots" content="INDEX,ALL" />
            <meta name="YahooSeeker" content="INDEX, FOLLOW" />
            <meta name="msnbot" content="INDEX, FOLLOW" />
            <meta name="googlebot" content="Default, follow" />
            <meta name="owner" content="isbmuniversity.edu.in/" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="2 days" />
            <meta property="og:title" content={course.metatitle} />
            <meta property="og:description" content={course.metadesc} />
            <meta
              property="og:url"
              content={`https://isbmuniversity.edu.in/course-details/${course.name}`}
            />
            <meta
              property="og:image"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta property="og:image:alt" content="ISBM University" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ISBM University" />
            <meta name="twitter:title" content={course.metatitle} />
            <meta name="twitter:description" content={course.metadesc} />
            <meta
              name="twitter:image:src"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@ISBMUniversity" />
            <meta name="twitter:creator" content="@ISBMUniversity" />
            <meta
              name="twitter:domain"
              content="https://isbmuniversity.edu.in/"
            />
            <meta property="twitter:account_id" content="758619542734053376" />
          </MetaTags>
          <Topnav />
          <Menubar />
          <div className={course.banner}>
            <div className="text_banners_abcds">
              <h1 className="animate__animated animate__fadeInRightBig">
                {course.title}
              </h1>
              <div
                className="animate__animated animate__fadeInRightBig"
                dangerouslySetInnerHTML={{ __html: course.banner_description }}
              ></div>
              <div className="d-flex">
                <div className="py-3" onClick={() => setModalShow(true)}>
                  <a className="btn-purple p-3 mx-auto">
                    <span className="gdlr-core-content">Apply Now</span>
                  </a>
                  <EnquiryNow
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
                <div className="p-3">
                  <a
                    className="btn-purple p-3 mx-auto"
                    download
                    href={Brochure}
                  >
                    <span className="gdlr-core-content">Download Brochure</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <section className="BannerSection__programStats">
            <ul className="BannerSection__programStats__box">
              <li className="BannerSection__programStats__static">
                <div className="BannerSection__programStats__static__label">
                  <div className="smartness-overloaded">Level</div>
                </div>
                <div className="BannerSection__programStats__static__desc">
                  {course.level}
                </div>
              </li>
              <li className="BannerSection__programStats__static">
                <div className="BannerSection__programStats__static__label">
                  <div className="smartness-overloaded">Duration</div>
                </div>
                <div className="BannerSection__programStats__static__desc">
                  {course.duration}
                </div>
              </li>
              <li className="BannerSection__programStats__static">
                <div className="BannerSection__programStats__static__label">
                  <div className="smartness-overloaded">
                    Eligibility Criteria
                  </div>
                </div>
                <div className="BannerSection__programStats__static__desc">
                  {course.eligibility}
                </div>
              </li>
              <li className="BannerSection__programStats__static">
                <div className="BannerSection__programStats__static__label">
                  <div className="smartness-overloaded">Download</div>
                </div>
                <div className="BannerSection__programStats__static__desc">
                  <a download href={Prospectus}>
                    School Prospectus
                  </a>
                </div>
              </li>
            </ul>
          </section>
          <Container className="About_us11">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/school-details/${course.school_name}`}>
                  {course.school_name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{course.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col lg={3} className="ordersss">
                <h4 className="programees">Schools</h4>
                <ul className="abcd1111">
                  {courseList.map((courseTitle) => (
                    <li
                      key={courseTitle.id}
                      className={
                        location.pathname ===
                        `/course-details/${courseTitle.name}`
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={`/course-details/${courseTitle.name}`}>
                        {courseTitle.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col lg={9}>
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="linkabout123">{course.title}</h2>
                    <div
                      className="AboutSection__list"
                      dangerouslySetInnerHTML={{ __html: course.description }}
                    ></div>
                    <h2 className="AboutSection__desc">Course Outcomes:</h2>
                    <div
                      className="AboutSection__list"
                      dangerouslySetInnerHTML={{ __html: course.outcome }}
                    ></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <div className="text-blue py-5">
            <div className="container">
              <div className="programs_row">
                <table className="w-100">
                  <tbody>
                    <tr>
                      <th>Programme</th>
                      <th>Duration</th>
                      <th>Mode</th>
                      <th>Eligibility</th>
                      <th>Gariyaband Campus</th>
                      <th>Raipur Campus(proposed)</th>
                    </tr>
                    {courseList.map((courseTitle) => (
                      <tr key={courseTitle.id}>
                        <td>
                          <Link to={`/course-details/${courseTitle.name}`}>
                            {courseTitle.title}
                          </Link>
                        </td>
                        <td>{courseTitle.duration}</td>
                        <td>{courseTitle.mode}</td>
                        <td>{courseTitle.eligibility}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: course.gariyaband_campus,
                            }}
                          />
                        </td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: course.raipur_campus,
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          <Allinone />
          <Footer />
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="video_courses_abcdsa"
          >
            <Modal.Body className="p-0 bg-transparent">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/jgS_lbVI428"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default CourseDetails;
