import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const CertProgramMDP = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <MetaTags>
        <title>Advance Your Career with Certificate Programs and MDPS</title>
        <meta name="Accelerate career development at ISBM University. Upscale your skills and expertise with our diverse range of Certificate Programs and MDPs." />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Advance Your Career with Certificate Programs and MDPS"
        />
        <meta
          property="og:description"
          content="Accelerate career development at ISBM University. Upscale your skills and expertise with our diverse range of Certificate Programs and MDPs."
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Advance Your Career with Certificate Programs and MDPS"
        />
        <meta
          name="twitter:description"
          content="Accelerate career development at ISBM University. Upscale your skills and expertise with our diverse range of Certificate Programs and MDPs."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Best Certificate Programs, Online Courses with Certificates, Management Development Programme in Chhattisgarh"
        />
      </MetaTags>

      <Topnav />
      <Menubar />
      <div className="certificateinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Certificate Programs And Mdps</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Certificate Programs And Mdps</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Certificate Programs</h2>
            
          <p>
              Short term certificate programs are conducted to enhance the skill
              of the youth the programs are trailer maid to meet the
              requirements of the business and industries.
            </p>
            <p >
              List of common certificate programs are listed below-
            </p>
            <ul class="mdp">
              <li >
                Certificate in Taxation
              </li>
              <li >
                Certificate in GST modules
              </li>
              <li >
                Certificate in Telly
              </li>
              <li >
                Certificate in E-commerce
              </li>
              <li >
                Certificate in M-commerce
              </li>
              <li >Certificate in GMP</li>
              <li >
                Certificate in Computer literacy
              </li>
              <li >
                Certificate in workshop maintenance
              </li>
              <li >
                Certificate in water shed management
              </li>
            </ul>

            <h2 className="linkabout123">
              Management Development Programs (MDPs)
            </h2>
            <p>
              The University conducts regular Management Development Programs
              (MDPs) programs for the betterment of the entry-level and
              middle-level executives. They can learn the basics of the
              Managerial traits by attending the programs.
            </p>
            <p>
              The list of common MDPs is given as under-
            </p>
            <strong>Multi-functional/Cross-disciplinary Programmes</strong>
            <ul class="mdp">
              <li >
                Leadership & Strategic Thinking
              </li>
              <li >
                General Management Program on Finance, Leadership and Project
                Management
              </li>
              <li >
                Programme on "Leadership Development and Credit Management"
              </li>
            </ul>

            <strong>Senior Leadership Programmes</strong>
            <ul class="mdp">
              <li >
                Training Programme on “Innovation & Entrepreneurship”
              </li>
              <li >
                Leadership Traits and Mind-set
              </li>
            </ul>

            <strong>Sales/Business-specific programs</strong>
            <ul class="mdp">
              <li >
                Effective Sales Management in the present-day ‘Insurance’ market
                in India
              </li>
              <li >
                Customer Relationship Management, for Senior Executives of an
                Oil & Gas major
              </li>
              <li >
                Portfolio Management and Sales Behavioural training programme,
                for Relationship Managers of an Asset Management Company
              </li>
            </ul>

            <strong>Functional Programmes</strong>
            <ul class="mdp">
              <li >
                Training Programme on Soft Skills
              </li>
              <li >Business Valuation</li>
              <li >
                Value Creation through Effective Supply Chain Management
              </li>
            </ul>
          </Col>
      
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CertProgramMDP;
