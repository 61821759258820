import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Schoolsidebaar = () => {
  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        <li>
          <Link to="/rushford-business-school">Rushford Business School</Link>
        </li>
        <li>
          <Link to="/henry-harvin">Henry Harvin</Link>
        </li>
      </ul>
    </Col>
  );
};

export default Schoolsidebaar;
