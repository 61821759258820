import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Aboutplacements = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="placementinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>About Placements</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>About Placements</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
            <h2 className="linkabout123">About Placements</h2>
            <p className="text-justify">
              ISBM University through its strong network and alliances is
              committed to provide 100% placement services to its students
              pursuing degree programme. It aims at creating resources and
              understanding of career opportunities, while enhancing student's
              transition from university to career.
            </p>
            <p className="text-justify">
              ISBM University endeavours to equip its students with all skills
              required for being employable in the industry, services,
              Government and Non-Government sector. To meet with this
              objective,The University has established its placement cell as an
              interface between the University and the corporate arena. This
              placement cell is responsible for the industry academy
              interaction, corporate training seminars and various workshops
              thus strengthening the corporate relations ultimately helping the
              students in good placements.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Aboutplacements;
