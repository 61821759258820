import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import mom2 from "../../Images/Ordinances andStatute/MOM of BOM meeting 25112022.pdf";
import EnquiryNow from "../common/EnquiryNow";


const Boardmanagement = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
   
      <Topnav />
      <Menubar />
      <div className="comminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Board of Management</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Board of Management</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <p>
              The Board of Management is a statutory body under the provisions
              of the Chhattisgarh Private Universities (Establishment and
              Operation) Act of 2005. The term of the nominated members of the
              Board of Management shall be of three years.
            </p>
            <p>Powers and functions of the Board of Management</p>

            <ol type="1">
              <li>
                To consider the sanction of teaching and non-teaching posts of
                the University and to recommend them to the Governing Body for
                approval.
              </li>
              <li>
                To get the financial approval of the Governing Body before
                implementing such decisions.
              </li>
              <li>
                To consider and approve the minutes of the selection committee
                for the appointment of teaching and non-teaching posts.
              </li>
              <li>
                To propose a fee structure to the competent authority for
                various programmes run by the University.
              </li>
              <li>
                To perform any other functions which may be assigned by the
                Governing Body/Chancellor.
              </li>
            </ol>

            <div className="row">
              <div className="col-12 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className=" card-header align-items-center table-title">
                    <h5 className="mb-0 text-white font-weight-bold">
                      Member of the Board of Management
                    </h5>
                  </div>
                  <div className="table-responsive ">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name of Member</th>
                          <th>Board Designation</th>
                          <th>Organizational Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Prof. (Dr) Anand Mahalwar</td>
                          <td>Chairperson </td>
                          <td>Vice Chancellor, ISBM University</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Ms. Bindu Mahesh Agrawal</td>
                          <td>Member</td>
                          <td>Nominee, Sponsoring Body, ISBM University</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Shri Jitendra Nahar</td>
                          <td>Member</td>
                          <td>Nominee, Sponsoring Body, ISBM University</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Dr. Ashok Kumar Nema</td>
                          <td>Member</td>
                          <td>
                            Associate Professor (Retd.) Pt. Ravi Shankar Shukla
                            University. Nominee &amp; Representative, State
                            Govt.
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Dr. C. L. Patel</td>
                          <td>Member</td>
                          <td>
                            Principal (Retd.) Govt. College Mahasamund (CG).
                            Nominee &amp; Representative, State Govt.
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Dr. N. Kumar Swamy</td>
                          <td>Member</td>
                          <td>
                            Associate Professor & Dean (In-charge), Faculty of
                            Sciences, ISBM University
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Dr. Poonam Verma</td>
                          <td>Member</td>
                          <td>Associate Professor & HOD Faculty of Science</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Dr. Garima Diwan</td>
                          <td>Member</td>
                          <td>
                            Associate Prof. & HOD, School of Arts & Humanities
                            Professor, ISBM University
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Dr. Sandeep Sahu</td>
                          <td>Member</td>
                          <td>
                            DSW & Assistant Professor Faculty of Art & Humanity
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Dr. Barada Prasad Bhol</td>
                          <td>Member Secretary</td>
                          <td>Registrar, ISBM University</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <a href={mom2} target={"/"}>
              <b className="text-decoration-underline text-darkblue fs-4">
                MOM of Board of Management meeting
              </b>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Boardmanagement;
