import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import abc1 from "../../Images/Hon'ble Persons/MsAnusuiya Uikey.webp";
import abc2 from "../../Images/Hon'ble Persons/umesh_patel.webp";
import abc3 from "../../Images/Hon'ble Persons/ts_singhdev.webp";
import abc4 from "../../Images/Hon'ble Persons/chattisgarh-niji-vishvavidyalaya.webp";
import abc5 from "../../Images/Hon'ble Persons/aa.webp";

import EnquiryNow from "../common/EnquiryNow";

 

const Message = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="msginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Messages of the Dignitaries</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Messages of the Dignitaries</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <div className="row justify-content-center mt-5 pt-5">
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc2}
                        alt="Hon. Minister of Higher Education, Government of Chhattisgarh Umesh Patel"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h4 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Umesh Patel
                      </h4>
                      <div className="mb-3 text-center">
                        <small className="text-muted">
                          Hon. Minister of Higher Education, Government of
                          Chhattisgarh
                        </small>
                      </div>
                      <div>
                        <small>
                          Umesh Nandkumar Patel is one of the most popular and
                          dynamic youth leaders of Indian National Congress. He
                          is an Indian politician, from Chhattisgarh. A member
                          of the Indian National Congress and has been the
                          president of the Chhattisgarh Youth Congress Committee
                          since September 2016. He represents the Kharsia
                          constituency in the Chhattisgarh Legislative Assembly.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc3}
                        alt="Hon. Minister of Medical Education Government of
                        Chhattisgarh Tribhuvaneshwar Saran Singh Deo"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Tribhuvaneshwar Saran Singh Deo
                      </h5>
                      <div className="mb-3 text-center">
                        Hon. Minister of Medical Education Government of
                        Chhattisgarh
                      </div>
                      <div>
                        Tribhuvaneshwar Saran Singh Deo or T. S. Singh Deo is an
                        Indian politician from Chhattisgarh. He is the current
                        Health Minister of Chhattisgarh. He is also the current
                        titular Maharaja of Surguja, with headquarters at
                        Ambikapur. He was the last mentor to sit on the throne
                        of Surguja.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc1}
                        alt="Hon. Governor of Chhattisgarh Anusuiya Uikey"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Anusuiya Uikey
                      </h5>
                      <div className="mb-3 text-center">
                        Hon. Governor of Chhattisgarh
                      </div>
                      <div>
                        Governor of the state is the academic leader. The
                        function of a Governor is to oversee the institutional
                        performance of the Universities. Anusuiya Uikey is
                        functioning as the Hon’ble Governor of Chhattisgarh. She
                        started her political career as a member of Legislative
                        Assembly. She is a tribal leader and rendered dedicated
                        service for the upliftment of the tribal community. As
                        visitor of the private Universities she has interest to
                        make Chhattisgarh as an education hub for the world. She
                        has interest to promote Chhattisgarhi language and
                        culture in the curriculum.
                        <a href="https://rajbhavancg.gov.in" target="_blank">
                          https://rajbhavancg.gov.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc4}
                        alt=" Chhattisgarh Niji Vishwavidyalaya Niyamak Aayog Dr. Umesh Kumar Mishra"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Dr. Umesh Kumar Mishra
                      </h5>
                      <div className="mb-3 text-center">
                        Chhattisgarh Niji Vishwavidyalaya Niyamak Aayog
                      </div>
                      <div>
                        Chhattisgarh Private Regulatory Commission, a statutory
                        body of the Govt. of Chhattisgarh. It is responsible for
                        providing guidance and supervision to the private
                        Universities Established in the State of Chhattisgarh.
                        The commission is dedicated to monitoring and
                        supervising the academic standard of private
                        Universities. The commission functions under the general
                        control of the Visitor i.e. the Hon’ble Governor of
                        Chhattisgarh. It consists of Chairman, Member Academic,
                        and Member Administration. The Secretary looks after the
                        functional aspect of the commission. The commission
                        provides a regulatory mechanism in order to ensure
                        appropriate standards in teaching, examination,
                        research, protecting the interest of the student, and
                        providing reasonable service conditions to the employees
                        working in private Universities.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc5}
                        alt="Hon. Home Minister and PWD Minister Government of
                        Chhattisgarh Tamradwaj Sahu"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Tamradwaj Sahu
                      </h5>
                      <div className="mb-3 text-center">
                        Hon. Home Minister and PWD Minister Government of
                        Chhattisgarh
                      </div>
                      <div>
                        Tamradwaj Sahu is a seasoned politician. He belongs to
                        the Indian National Congress Party. Born in an ordinary
                        family of Patora village of Bemetara district in (then
                        Madhya Pradesh) Chhatisgarh, Sahu could receive his
                        formal education only till higher secondary. He has been
                        active in social service from a young age and started
                        political journey as a congressman to rose through the
                        ranks gradually. He worked for Madhya Pradesh Congress
                        Seva Dal, then as Mahamantri of Madhya Pradesh Congress
                        Committee and Chairman of Madhya Pradesh Text Book
                        corporation. Mr Sahu won his maiden election for Madhya
                        Pradesh Legislative Assembly in 1998 and then went won
                        to winning 2003, 2008 and 2018 for Chhattisgarh Assembly
                        from the Durg constituency. He was elected to the 16th
                        Lok Sabha from Durg (Lok Sabha constituency),
                        Chhattisgarh in 2014 as a candidate of Indian National
                        Congress. When Chhattisgarh got statehood in 2000,
                        Tamradwaj Sahu was appointed as the minister of state
                        for Water Resources, Energy and Education, Science and
                        Technology, Agriculture, Animal Husbandry and
                        Cooperative. He has also worked as a member of the
                        standing committee for official language, coal and
                        steel, petroleum and natural gas in Parliament during
                        his tenure as a Member of Lok Sabha from 2014 - 2018.
                        Mr. Sahu was appointed as the Home Minister in the
                        Chhattisgarh state government as the congress came back
                        to power after over a decade in 2018. He was also made
                        the cabinet minister for Public Construction, Jail,
                        Religious Trust, and Tourism and Culture portfolios.
                        <br />
                        <br />
                        <a
                          href="https://en.wikipedia.org/wiki/Tamradhwaj_Sahu"
                          target="_blank"
                        >
                          https://en.wikipedia.org/wiki/Tamradhwaj_Sahu
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Message;
