import React, { useEffect, useState, useRef } from "react";
import logo from "../../Images/logo/isbm-university.webp";
import { Modal } from "react-bootstrap";
import $ from "jquery";

function EnquiryNow({ show, onHide }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (show) {
      const fetchCity = () => {
        var state = $("#state").val();

        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_cities.php",
          data: { state },
          dataType: "json",
          success: function (response) {
            var city = $(".city");
            city.empty();
            response.cities.forEach((name) => {
              city.append($("<option>", { value: name, text: name }));
            });
          },
          error: function (error) {
            console.error("Error fetching cities:", error);
          },
        });
      };

      const fetchCourse = () => {
        var level = $("#level").val();
        var entity = "ISBMU";

        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_courses.php",
          data: { level, entity },
          dataType: "json",
          success: function (response) {
            var course = $(".course");
            course.empty();
            response.courses.forEach((name) => {
              course.append($("<option>", { value: name, text: name }));
            });
          },
          error: function (error) {
            console.error("Error fetching courses:", error);
          },
        });
      };

      // Fetch states and levels when the component mounts
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_states.php",
        dataType: "json",
        success: function (response) {
          var state = $(".state");
          state.empty();
          response.states.forEach((name) => {
            state.append($("<option>", { value: name, text: name }));
          });
        },
        error: function (error) {
          console.error("Error fetching states:", error);
        },
      });

      var entity = "ISBMU";
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_levels.php",
        data: { entity },
        dataType: "json",
        success: function (response) {
          var level = $(".level");
          level.empty();
          response.levels.forEach((name) => {
            level.append($("<option>", { value: name, text: name }));
          });
        },
        error: function (error) {
          console.error("Error fetching levels:", error);
        },
      });

      $(".state").on("change", fetchCity);
      $(".level").on("change", fetchCourse);

      return () => {
        $(".state").off("change", fetchCity);
        $(".level").off("change", fetchCourse);
      };
    }
  }, [show]);

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    // Gather form data
    const formData = new FormData(e.target);

    // Send form data using fetch
    fetch("https://isbmuniversity.edu.in/submitLead.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully");
          // Handle successful submission
        } else {
          console.error("Form submission failed");
          // Handle submission failure
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmitting(false); // Reset button after submission is complete
        }, 2000); // Example delay - replace with actual form submission logic
      });
    
  };

  return (
    <Modal
      className="enquiry_formss"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ display: "initial" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          <img alt="enquire now" className="modallogo" src={logo} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="abcd_modal_abcd">
        <form
          ref={formRef}
          action="https://isbmuniversity.edu.in/submitLead.php"
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control mt-4 mb-3"
              id="name"
              name="name"
              placeholder="Enter your Name"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="Email"
              className="form-control mb-3"
              id="email"
              name="email"
              placeholder="Enter your Email"
              required
            />
          </div>
          <div className="form-group mb-3" style={{ display: "flex" }}>
            <input
              type="number"
              maxLength="10"
              style={{ width: "100%" }}
              id="mobile_code"
              className="form-control"
              placeholder="Phone Number"
              name="phone"
              autoComplete="off"
              required
            />
          </div>
          <div className="form-group mb-3">
            <select
              className="form-control state"
              id="state"
              name="state"
              required
            >
              <option value="">Select State</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <select
              className="form-control city"
              id="city"
              name="city"
              required
            >
              <option value="">Select City</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <select
              className="form-control level"
              id="level"
              name="level"
              required
            >
              <option value="">Select level</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <select
              className="form-control course"
              id="course"
              name="course"
              required
            >
              <option value="">Select course</option>
            </select>
          </div>
          <input
            type="text"
            className="form-control"
            id="current_url"
            name="current_url"
            value={`${window.location.href}`}
            hidden
          />
          <input
            type="text"
            name="utm_source"
            value={
              new URLSearchParams(window.location.search).get("utm_source") ||
              ""
            }
            hidden
          />
          <input
            type="text"
            name="utm_medium"
            value={
              new URLSearchParams(window.location.search).get("utm_medium") ||
              ""
            }
            hidden
          />
          <input
            type="text"
            name="utm_campaign"
            value={
              new URLSearchParams(window.location.search).get("utm_campaign") ||
              ""
            }
            hidden
          />
          <div className="form-group d-flex justify-content-center">
            <input
              type="submit"
              name="submit"
              value="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EnquiryNow;
