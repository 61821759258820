import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useLocation } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { MetaTags } from "react-meta-tags";
import Footer from "../common/footer";
import EnquiryNow from "../common/EnquiryNow";
import Allinone from "../common/Allinone";
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";
import Brochure from "../../Images/Brochure/ISBM_PROSPECTUS.pdf";

const SchoolDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const { schoolName } = useParams();
  const [courses, setCourses] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [schools, setSchools] = useState([]);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow2(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/coursesList/${schoolName}`
        );
        const coursesData = response.data.data;
        setCourses(coursesData);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (schoolName) {
      fetchCourses();
    }
  }, [schoolName]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        const schoolsData = response.data.data;
        setSchools(schoolsData);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/schools/${schoolName}`
        );
        const schoolData = response.data.data[0];
        setSchoolDetails(schoolData);
      } catch (error) {
        setError("Error fetching school details");
        console.error("Error fetching school details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (schoolName) {
      fetchSchoolDetails();
    }
  }, [schoolName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {schoolDetails && (
        <>
          <MetaTags>
            <title>{schoolDetails.metatitle}</title>
            <meta name="description" content={schoolDetails.metadesc} />
            <link
              rel="canonical"
              href={`https://isbmuniversity.edu.in/school-details/${schoolDetails.name}`}
            />
            <meta
              name="SearchEngines"
              content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
                DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
            />
            <meta name="Robots" content="INDEX, FOLLOW" />
            <meta
              name="Author"
              content="ISBM University, isbmuniversity.edu.in/"
            />
            <meta name="Robots" content="INDEX,ALL" />
            <meta name="YahooSeeker" content="INDEX, FOLLOW" />
            <meta name="msnbot" content="INDEX, FOLLOW" />
            <meta name="googlebot" content="Default, follow" />
            <meta name="owner" content="isbmuniversity.edu.in/" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="2 days" />
            <meta property="og:title" content={schoolDetails.metatitle} />
            <meta property="og:description" content={schoolDetails.metadesc} />
            <meta
              property="og:url"
              content={`https://isbmuniversity.edu.in/school-details/${schoolDetails.name}`}
            />
            <meta
              property="og:image"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta property="og:image:alt" content="ISBM University" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ISBM University" />
            <meta name="twitter:title" content={schoolDetails.metatitle} />
            <meta name="twitter:description" content={schoolDetails.metadesc} />
            <meta
              name="twitter:image:src"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@ISBMUniversity" />
            <meta name="twitter:creator" content="@ISBMUniversity" />
            <meta
              name="twitter:domain"
              content="https://isbmuniversity.edu.in/"
            />
            <meta property="twitter:account_id" content="758619542734053376" />
          </MetaTags>
          <Topnav />
          <Menubar />
          <div>
            <div className={schoolDetails.banner}>
              <div className="text_banners_abcds">
                <h1 className="animate__animated animate__fadeInRightBig">
                  {schoolDetails.title}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: schoolDetails.banner_description,
                  }}
                />
                <div className="d-flex">
                  <div className="py-3" onClick={() => setModalShow(true)}>
                    <a className="btn-purple p-3 mx-auto">
                      <span className="gdlr-core-content">Apply Now</span>
                    </a>
                    <EnquiryNow
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <div className="p-3">
                    <a
                      className="btn-purple p-3 mx-auto"
                      download
                      href={Brochure}
                    >
                      <span className="gdlr-core-content">
                        Download Brochure
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Container className="About_us11">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{schoolDetails.name}</Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col lg={3}>
                  <h4 className="programees">Schools</h4>
                  <ul className="abcd1111">
                    {Array.isArray(schools) &&
                      schools.map((school) => (
                        <li
                          key={school.id}
                          className={
                            location.pathname ===
                            `/school-details/${school.name}`
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={`/school-details/${school.name}`}>
                            {school.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col lg={9}>
                  <div className="row">
                    <div className="col-lg-9">
                      <h2 className="linkabout123">Overview</h2>
                      <div
                        className="AboutSection__list"
                        dangerouslySetInnerHTML={{
                          __html: schoolDetails.description,
                        }}
                      />
                      <h2 className="AboutSection__desc">Outcomes:</h2>
                      <div
                        className="AboutSection__list"
                        dangerouslySetInnerHTML={{
                          __html: schoolDetails.outcome,
                        }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="AboutSection__testimonial">
                        <div
                          className="AboutSection__testimonial__desc has-logo"
                          dangerouslySetInnerHTML={{
                            __html: schoolDetails.sidebar_description,
                          }}
                        />
                        <div className="brand-icon-stopper brand-icon-retina brand-icon-retina_LBS_testimonial__1581430299500"></div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="py-5">
              <div className="container">
                <div className="programs_row">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <th>Programme</th>
                        <th>Duration</th>
                        {/* <th>Mode</th>
                        <th>Eligibility</th> */}
                        <th>Gariyaband Campus</th>
                        <th>Raipur Campus(proposed)</th>
                      </tr>
                      {courses.map((course) => (
                        <tr key={course.id}>
                          <td>
                            <Link to={`/course-details/${course.name}`}>
                              {course.title}
                            </Link>
                          </td>
                          <td>{course.duration}</td>
                          {/* <td>{course.mode}</td>
                          <td>{course.eligibility}</td> */}
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: course.gariyaband_campus,
                              }}
                            />
                          </td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: course.raipur_campus,
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Allinone />
            <Footer />
            <Modal
              show={show2}
              onHide={handleClose}
              centered
              className="video_schools_abcdsa"
            >
              <Modal.Body className="p-0 bg-transparent">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/jgS_lbVI428"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default SchoolDetails;
