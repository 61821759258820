import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Form, Button } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Complaintscast = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Complaint Against Caste-based discrimination</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Complaint Against Caste-based discrimination</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
          <Row className="complaintcaste">
              <Col lg={12}>
                <div style={{ border: "solid #06355F 1px" }}>
                  <h4>COMPLAINT AGAINST CASTE-BASED DISCRIMINATION</h4>
                  <Form className="Form_complaint">
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Courses</Form.Label>
                      <Form.Control type="text" placeholder="Enter Courses" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Academic Year</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Academic Year"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Enrollement No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Enrollement No"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone No</Form.Label>
                      <Form.Control type="text" placeholder="Enter Phone No" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        Brief Description of the Complaint*
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Brief Description"
                        as="textarea"
                        rows={3}
                      />
                    </Form.Group>
                    <div style={{ marginBottom: "10px" }}>
                      <Button variant="success">Submit</Button>&nbsp;&nbsp;
                      <Button variant="danger">Reset</Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Complaintscast;
