import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Sidebaar = () => {
  const location = useLocation();

  const menuItems = [
    { path: "/about-us", label: "About ISBM University" },
    { path: "/vision-mission", label: "Vision and Mission" },
    { path: "/honorable-visitor", label: "Hon'ble Visitor" },
    { path: "/honorable-patron", label: "Hon'ble Patron" },
    { path: "/honorable-chancellor", label: "Hon'ble Chancellor" },
    { path: "/directorgeneral", label: "Director General" },
    { path: "/honorable-vice-chancellor", label: "Hon'ble Vice-Chancellor" },
    { path: "/official-registar", label: "Registrar" },
    { path: "/governing-body", label: "Governing Body" },
    { path: "/message", label: "Messages of the Dignitaries" },
    { path: "/board-management", label: "Board of Management" },
    { path: "/academic-council", label: "Academic Council" },
    { path: "/board-advisor", label: "Board of Advisors" },
    { path: "/other-communitie", label: "Other Committees" },
    { path: "/statues-and-ordinance", label: "Statutes and Ordinances" },
    { path: "/recognisation", label: "Recognitions and Approvals" },
    { path: "/awards", label: "Awards" },
    { path: "/media-coverage", label: "Media Coverage" },
    { path: "/RTI", label: "RTI" },
    { path: "/code-of-conduct-faculty", label: "Code of Conduct (Faculty)" },
    {
      path: "/code-of-conduct-management",
      label: "Code of Conduct (Management)",
    },
    { path: "/code-of-conduct-student", label: "Code of Conduct (Student)" },
    { path: "/code-of-conduct-boardes-of-hostel", label: "Code of Conduct (Boardes of Hostel)" },
  ];

  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">About us</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Sidebaar;
