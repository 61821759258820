import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";

const ProgrammesAndEligibilities = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Programmes and Eligibilities</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Programmes and Eligibilities</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Programmes and Eligibilities</h2>
            <p>
              We provide management education with innovative applied research
              and practice to educate business leaders of tomorrow. Our
              graduates make a difference by creating real value for society by
              emphasizing a focus on a holistic multi-disciplinary, and
              multi-cultural approach to learning.
            </p>
            <p>
              Interaction with business leaders and entrepreneurs, industrial
              visits and workshops, prepares the students to be leaders who can
              address the most urgent and challenging problems that the world is
              facing today.
            </p>
            <p>
              Personality enhancement and practical experience through a
              continuous process of industry-academia interface are facilitated,
              thereby making them socially responsible, culturally aware and
              ethically better citizens of today and tomorrow.
            </p>
            <p>
              Taught interactively through real-client, project-based learning,
              and regular networking opportunities, students undergo a
              real-world transformative learning experience.
            </p>
            <p>
              Our graduates are valued for their well-rounded education, many
              enjoying handsome starting salaries and career opportunities.
              Always future-ready, they are well prepared to make a difference
              in business and society.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgrammesAndEligibilities;
