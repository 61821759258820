import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function News() {
  const baseUrl = "admin-portal/admin-assets/news-banners/";

  const [newss, setNewss] = useState([]);

  useEffect(() => {
    const fetchNewss = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/newsApi"
        );
        setNewss(response.data.data);
        console.log(response);
      } catch (error) {
        console.error("Error fetching newss:", error);
      }
    };

    fetchNewss();
  }, []);
  return (
    <React.Fragment>
      <Col className="container py-5">
        <Row>
          <h3 class="col-2 news-events-title">News</h3>
          <div class="col-10 news-events-title-divider h-0 m-auto"></div>
        </Row>
        <div class="row position-relative mt-n1-9">
          {newss.map((news) => (
            <div key={news.id} className="col-lg-4 col-md-6 col-sm-12 p-3">
              <div class="team-style1 text-center">
                <img
                  src={baseUrl + news.banner}
                  class="border-radius-5"
                  alt={news.title}
                  style={{ height: "400px" }}
                ></img>
                <div class="team-info">
                  <h4 class="text-wite mb-1 h4">{news.title}</h4>
                </div>
                <div class="team-overlay">
                  <Link
                    class="text-right font-weight-bold"
                    to={`/news-details/${news.id}`}
                  >
                    <div class="d-table h-100 w-100">
                      <div class="d-table-cell align-middle">
                        <h4 class="text-white mb-1 h4">{news.title}</h4>
                        <ul class="social-icon-style1">
                          <li>
                            <Link
                              class="text-right font-weight-bold"
                              to={`/news-details/${news.id}`}
                            >
                              Read More
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </React.Fragment>
  );
}

export default News;
