import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Form, Button } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const  Placementregistration = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="globalinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Placement Form</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Placement Form</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Placement Registration Form</h2>
          <Form action="http://localhost/phdQueries.php" method="POST">
                  <div className="card p-4">
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>Personal Information: </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label>Name: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="name"
                            required
                            placeholder="Enter Full Name"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Email Address: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="email"
                            required
                            placeholder="Enter Email Address"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Contact Number: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="phone"
                            required
                            placeholder="Enter Contact Number"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>DOB: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="dob"
                            required
                            placeholder="Enter Date of Birth"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Gender: </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="gender"
                          >
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Other">Other</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>State: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="state"
                            required
                            placeholder="Enter State"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>City: </Form.Label>
                          <Form.Control
                            requiredtype="text"
                            name="city"
                            required
                            placeholder="Enter City"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label>Address: </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            required
                            placeholder="Enter Your Full Address"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                  </div>
                  <div className="card p-4 mt-4">
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>Academic Qualifications: </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>Xth : </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xpassingyr"
                            required
                            placeholder="Paasing Year"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xboardname"
                            required
                            placeholder="Board Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xperc"
                            required
                            placeholder="Percentage"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>XIIth : </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xiipassingyr"
                            required
                            placeholder="Paasing Year"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xiiboardname"
                            required
                            placeholder="Board Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="xiiperc"
                            required
                            placeholder="Percentage"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Label>Graduation : </Form.Label>
                        </Form.Group>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="graduationpassingyr"
                            required
                            placeholder="Paasing Year"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="graduationboardname"
                            required
                            placeholder="Board Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-4">
                        <Form.Group className="mb-3">
                          <Form.Control
                            requiredtype="text"
                            name="graduationperc"
                            required
                            placeholder="Percentage"
                          />
                        </Form.Group>
                      </div>
                    </Row>
                  </div>
                  <div className="card p-4 mt-4">
                    <Form.Group controlId="formFileSm" className="mb-3">
                      <Form.Label>Upload Your CV</Form.Label>
                      <Form.Control type="file" size="sm" />
                    </Form.Group>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </Button>
                </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Placementregistration;
