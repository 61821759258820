import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import about from "../../Images/commonimg/isbm12.webp";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow";

import { MetaTags } from "react-meta-tags";
import AdmissionFormModal from "../common/AdmissionFormModal";

const Aboutus = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [modalShow1, setModalShow1] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>ISBM University Empowering Through Affordable Education</title>
        <meta
          name="description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          name="keywords"
          content="ISBM University Chhattisgarh, Best university in chhattisgarh, ISBM university raipur"
        />

        <link rel="canonical" href="https://isbmuniversity.edu.in/about-us" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University Empowering Through Affordable Education"
        />
        <meta
          property="og:description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/about-us/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University Empowering Through Affordable Education"
        />
        <meta
          name="twitter:description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="abtinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Empowering Futures: ISBM University</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>About Us</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <div className="row gy-4">
              <div className="col-lg-5 position-relative about-img"></div>
              <div className="col-lg-7 d-flex align-items-end">
                <div className="content ps-0 ps-lg-5">
                  <p className="font-weight-bold" style={{ color: "#A82682" }}>
                    The University is established and approved by the Government
                    of Chhattisgarh under the Chhattisgarh Private Universities
                    (Establishment and Operation) Act, 2005 (Amendment Act 2016)
                    to confer Certificate Diploma, PG Diploma, Degree and
                    Doctorate Degree to the students.
                  </p>
                  <p>
                    ISBM University programmes are research driven, skill based
                    and knowledge intensive. The University is a centre of
                    academic innovation and research where students and faculty
                    collaborate to learn, research, create, innovate, inspire
                    and incubate.
                  </p>
                  {/* <div className="text_banners_abcds">
          <h1>Empowering Futures: ISBM University</h1>
          <div onClick={() => setModalShow1(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <AdmissionFormModal show={modalShow1} onHide={() => setModalShow1(false)} />
        </div> */}
                  <p>
                    ISBM University endeavors to offer services across the
                    student's life cycle not only supported by teaching and
                    learning but also with career development, skill
                    enhancement, employment opportunities, business, incubation
                    and fund raising for development initiatives. It is
                    committed to prepare its students to face the challenges of
                    new age society. It also strives to improve the quality of
                    employment of the people by academic interventions.
                  </p>

                  <div className="position-relative mt-4">
                    <img src={about} className="img-fluid" alt="Welcome to ISBM university" />
                    <a
                      href={"https://www.youtube.com/embed/jMTzToBXj0Q"}
                      className=" play-btn"
                    ></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gy-4 py-5">
              <div className="col-lg-4 bg-blue text-white">
                <div className="whybox">
                  <h2 className="font-weight-bold">
                    Why Choose ISBM University?
                  </h2>
                  <p className="mb-30px">
                    ISBM University is a new age University to provide
                    industry-driven programmes in Management | Commerce |
                    Science | Engineering & Technology | Information Technology
                    | Design | Pharmacy Life Science / Yoga & Naturopathy | Arts
                    & Humanities | Law | Library & Information Science |
                    Journalism & Mass Communication | Education | Hotel
                    Management | Health & Allied Science | Vocational Management
                    | Media & Entertainment in order to meet the requirements of
                    the industry.
                  </p>
                </div>
              </div>

              <div className="col-lg-8 d-flex align-items-center">
                <div className="row gy-4">
                  <div
                    className="col-xl-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="fa fa-line-chart"></i>
                      <h3 className="font-weight-bold text-purple abouttext">
                        Encourages students to develop their entrepreneurial
                        skills
                      </h3>
                    </div>
                  </div>
                  <div
                    className="col-xl-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="fa fa-graduation-cap"></i>
                      <h3 className="font-weight-bold text-purple abouttext">
                        The university's focus on innovation, entrepreneurship,
                        leadership, and globalization
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-xl-6"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="fa fa-star" aria-hidden="true"></i>
                      <h3 className="font-weight-bold text-purple abouttext">
                        Committed to innovation in education
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-xl-6"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="fa fa-trophy"></i>
                      <h3 className="font-weight-bold text-purple abouttext">
                        Committed to preparing students for the globalized world
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <section id="stats-counter" className="stats-counter">
        <div className="container">
          <h2>Creating an Inspiring Environment for Learning and Growth</h2>
          <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="wifi"></span>
                <p>Wifi Campus</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="greenCampus"></span>
                <p>Lush & Green Campus</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="learningEvn"></span>
                <p>Apt Learning Environment</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="sportsFacility"></span>
                <p>Sports Facilities</p>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="temple"></span>
                <p>Temple</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="recreationRoom"></span>
                <p>Recreation Room</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="seminar"></span>
                <p>Seminar Hall</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="hostel"></span>
                <p>Girls & Boys Hostels</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placement_sec1">
        <h2>Choose a University Known for Top Placements</h2>
        <section className="placement_sec"></section>
        <h6 className="position-absolute top-50 ms-3 text-white fst-italic p-2">
          Connect with Industry-leading
          <br /> Placement Opportunities
        </h6>
      </section>
      <section className="mob_placement_sec1">
        <h2 className="text-white font-weight-bold text-center mb-3">
          Choose a University Known for Top Placements
        </h2>
        <section className="mob_placement_sec"></section>
      </section>
      <Footer />
    </>
  );
};

export default Aboutus;
