import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import anand from "../../Images/Hon'ble Persons/anand.webp";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const Vicechancellor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <> 
      <MetaTags>
        <title>Pioneering Education | ISBM University | Vice Chancellor</title>
        <meta
          name="description"
          content="ISBM University's Honorable Vice Chancellor embodies visionary leadership. Explore his impactful message for shaping the future of education and learning. "
        />
        <link
          rel="canonical"
          href="https://isbmuniversity.edu.in/honorable-vice-chancellor"
        />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Pioneering Education | ISBM University | Vice Chancellor "
        />
        <meta
          property="og:description"
          content="ISBM University's Honorable Vice Chancellor embodies visionary leadership. Explore his impactful message for shaping the future of education and learning."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/honorable-vice-chancellor/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Pioneering Education | ISBM University | Vice Chancellor "
        />
        <meta
          name="twitter:description"
          content="ISBM University's Honorable Vice Chancellor embodies visionary leadership. Explore his impactful message for shaping the future of education and learning."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Vision for Education , Higher Education , Degree Programs"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>From The Vice Chancellor's Desk</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hon'ble Vice Chancellor</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Row>
              <Col lg={4}>
                <div className="honor_div">
                  <img className="img_honor" src={anand} alt="Hon'ble Vice Chancellor Pro. (Dr.) Anand Mahalwar"/>
                </div>
              </Col>
              <Col lg={8}>
                <h2 className="honor_name">Pro. (Dr.) Anand Mahalwar</h2>
                <p className="text-justify">
                  It gives me great pleasure in extending my hearty welcome to
                  all who are seeking admission to various Programmes of ISBM
                  University. The University is a new-age University committed
                  to provide innovative learning programmes to meet the current
                  requirements of the society & industry. The programmes offered
                  are research driven, skill based and knowledge intensive. The
                  University is envisioned to be a centre of academic innovation
                  and research where students and faculty will collaborate
                  together to learn, research, create, innovate, inspire and
                  incubate
                </p>
                <p className="text-justify">
                  The ISBM University is established and approved by the
                  Government of Chhattisgarh under the Chhattisgarh Private
                  Universities (Establishment and Operation) Act, 2005
                  (Amendment Act 2016). It is recognized by the UGC and is an
                  active member of the Association of Indian Universities (AIU).
                  The University endeavors to offer services across the
                  student-life cycle, supporting students not only with teaching
                  and learning, but also with career development, skill
                  enhancement, employment opportunities, business incubation,
                  fund raising, and mentoring. The University's philosophy
                  aligns with the famous quote of Late Shri Mahatma Gandhi:
                  <b>
                    "What is really needed to make democracy function is not
                    knowledge of facts, but right education."
                  </b>
                </p>
                <p className="text-justify">
                  The objective of the University is to provide higher education
                  at the door steps of the aspirants. At ISBM, it is believed
                  that imparting need-based, high- quality knowledge is the need
                  of the hour in order to nurture young minds successfully to
                  meet challenges of today's scenario, and to make them bloom as
                  leaders to guide society.
                </p>
                <p className="text-justify">
                  Highly qualified members of the faculty, quality
                  infrastructure, well-equipped laboratories with all required
                  sophisticated instruments, the library with a large collection
                  of reference, text books and periodicals accelerates the
                  students to become the leaders of the society. It is believed
                  that a healthy mind resides in a healthy body thus the
                  University is having good facilities for sports and games and
                  co-curricular activities for the holistic development of the
                  students.
                </p>
                <p className="text-justify">
                  The University looks forward to welcome highly motivated
                  students to become leaders in future.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Vicechancellor;
