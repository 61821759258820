import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function SearchCourse() {
  const [schools, setSchools] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        setSchools(response.data.data || []);
      } catch (error) {
        setError("Error fetching schools");
        console.error("Error fetching schools:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedSchool && selectedLevel) {
        try {
          setLoading(true);
          const response = await axios.get(
            `https://isbmuniversity.edu.in/admin-portal/fetchCourses?school=${selectedSchool}&level=${selectedLevel}`
          );
          setCourses(response.data.courses || []);
        } catch (error) {
          setError("Error fetching courses");
          console.error("Error fetching courses:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setCourses([]);
      }
    };

    fetchCourses();
  }, [selectedSchool, selectedLevel]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCourse) {
      const courseName = courses.find(
        (course) => course.name === selectedCourse
      ).name;
      navigate(`/course-details/${courseName}`);
    } else {
      alert("Please select a course");
    }
  };

  return (
    <React.Fragment>
      <Row>
        <div className="col-lg-8 col-md-6 col-sm-12 text-white text-center search_courses">
          <div className="p-5 search_box">
            <h2 className="fw-bold pb-3">Search For Courses</h2>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            <form data-gtm-form-interact-id="0" onSubmit={handleSubmit}>
              <Row className="flex-wrap mb-3">
                <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                  <select
                    className="w-100 p-3"
                    value={selectedSchool}
                    onChange={(e) => setSelectedSchool(e.target.value)}
                  >
                    <option value="">Select a school</option>
                    {schools.map((school) => (
                      <option key={school.id} value={school.name}>
                        {school.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                  <select
                    className="w-100 p-3"
                    value={selectedLevel}
                    onChange={(e) => setSelectedLevel(e.target.value)}
                    disabled={!selectedSchool}
                  >
                    <option value="">Select a level</option>
                    <option value="Certificate">Certificate</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Undergraduate">Undergraduate</option>
                    <option value="Postgraduate">Postgraduate</option>
                    <option value="Doctorate">Doctorate</option>
                  </select>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                  <select
                    className="w-100 p-3"
                    value={selectedCourse}
                    onChange={(e) => setSelectedCourse(e.target.value)}
                    disabled={!selectedLevel}
                  >
                    <option value="">Select a course</option>
                    {Array.isArray(courses) &&
                      courses.map((course) => (
                        <option key={course.id} value={course.name}>
                          {course.title}
                        </option>
                      ))}
                  </select>
                </div>
              </Row>
              <button className="btn-blue py-3 px-5 border-0" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 bg-blue p-5">
          <Row>
            <i className="fa fa-link" aria-hidden="true"></i>
            <h3 className="quickLinks-title">Quick Links</h3>
            <ul className="quickLinks">
              <li>After 10th Programmes</li>
              <li>After 12th Programmes</li>
              <li>After Graduation Programmes</li>
              <li>After Post-Graduation Programmes</li>
              <li>Lateral Entry</li>
              <li>Apply Online</li>
            </ul>
          </Row>
        </div>
      </Row>
    </React.Fragment>
  );
}

export default SearchCourse;
