import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion} from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import big_vacany from "../../Images/logo/big_vacancyy.webp";
import EnquiryNow from "../common/EnquiryNow";
 

const Campustraining = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="traininginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Campus Recruitment & Interview Training Apply Now</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>
            Campus Recruitment & Interview Training Apply Now
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              Campus Recruitment & Interview Training Apply Now
            </h2>
            <p className="text-justify">
              The interview itself is merely an exercise of two-way
              communication between the recruiter and the student and each
              attempts to "sell" the other. The recruiter "sells" the job and
              his/her company while the student "sells" himself or herself as
              the person best suited to fill it. The Key to do well in an
              interview is to prepare well and the Career Services will help
              students by offering following interview training services:
            </p>
            <ol type="1" > 
              <li >Mock Interviews</li>
              <li>Videotaped Mock Interviews</li>

              <li>Case Preparation</li>

              <li>Salary Negotiation</li>

              <li>Job Search Techniques</li>

              <li>Presentation Skills</li>

              <li>Dressing for Success</li>

              <li>Networking</li>

              <li>Online Networking</li>

              <li>
                Informational Interviews with Alumni and Industry Professionals
              </li>
            </ol>
            <p >
              <b>
                The Career and Placement Cell is powered by India's leading Job
                Portal,
              </b>
            </p>
            <img
              src={big_vacany}
              alt="BigVacancy Career and Placement"
              className="pb-4"
            />
            <Accordion className="text-justify" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header> Paid Internship in USA</Accordion.Header>
                <Accordion.Body>
                  <p >
                    Room Services Division / Food And Beverages / Culinary /
                    Office Administrative/IT / Management training
                  </p>
                  <p >
                    Programmes provide international qualified candidates with
                    paid placements in locations throughout the United States,
                    including New Jersey, Virginia, Georgia, Florida,
                    Mississippi, South Carolina, Philadelphia, Boston,
                    California, and many other locations throughout the US. If
                    you have ever dreamed of traveling, training, earning money
                    and living in America, AMLINE is the right choice for you.
                  </p>
                  <p>
                    <b>Training/Internship Programmes Include:</b>
                  </p>
                  <p>
                    J-1 visa up to 12 months (non-hospitality up to 18 months)
                  </p>
                  <p>
                    The Internship Programme places professionals in their field
                    of expertise.
                  </p>
                  <p>
                    Highly qualified, motivated and professional individuals add
                    to your work culture.
                  </p>
                  <b>Benefits:</b>
                  <ul>
                    <li>
                      University students and graduates in professional
                      categories.
                    </li>

                    <li>Interview before selecting candidates.</li>

                    <li>
                      Save on payroll taxes! No FICA or FUTA taxes. (State
                      unemployment rules vary by state.)
                    </li>

                    <li>Customized training plan.</li>
                  </ul>
                  <p>
                    <b>Work & Travel Programme includes:</b>
                  </p>
                  <p>J-1 visa up to 4 to 6 months</p>
                  <p>
                    This is an exchange programme that assists companies with
                    meeting current labor demands by bringing international
                    university students to the U.S., on their summer, spring or
                    winter break
                  </p>
                  <b>Benefits:</b>
                  <ul>
                    <li>
                      State Department designated J-1 visa sponsor for
                      Work/Travel.
                    </li>

                    <li>
                      Ideal for companies with additional staffing needs in the
                      winter, spring or summer months.
                    </li>

                    <li>
                      Save on payroll taxes! No FICA or FUTA taxes (State
                      unemployment rules vary by state.)
                    </li>
                  </ul>
                  <b >
                    Total fees cover the following expenses and services
                  </b>
                  <ul >
                    <li>
                      <b>Placement Match</b>
                    </li>
                    <li>
                      <b>DS-2019 Form</b>
                    </li>
                    <li>
                      <b>SEVIS Fee</b>
                    </li>
                    <li>
                      <b>Health Insurance</b>
                    </li>
                    <li>
                      <b>Ongoing Support</b>
                    </li>
                  </ul>
                  <b >Total fees do not cover the following</b>
                  <ul >
                    <li>
                      <b>Transportation</b>
                    </li>
                    <li>
                      <b>Personal expenses</b>
                    </li>
                    <li>
                      <b>Air tickets</b>
                    </li>
                  </ul>
                  <b >
                    If you have any questions or query feel free to contact us
                    anytime.
                  </b>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Campustraining;
