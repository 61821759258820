import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Internationalsidebaar = () => {
  const location = useLocation();

  const menuItems = [
    {
      path: "/international-partnerships",
      label: "International Partnerships",
    },
    { path: "/international-students", label: "International Students" },
    {
      path: "/prospective-international-students",
      label: "Prospective International Students",
      subLabel:
        "(Students going abroad under ISBMU International joint university programme)",
    },
  ];
  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Internationalsidebaar;
