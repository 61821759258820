import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import EnquiryNow from "../common/EnquiryNow";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ProgrammedDetails = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [schools, setSchools] = useState([]);
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        const schoolsData = response.data.data;
        setSchools(schoolsData);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);
  const location = useLocation();
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="allprogrammesimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>All Programmes</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>All Programmes</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col>
            <h2 className="linkabout123">All Programmes</h2>
            <div class="row">
              <p className="text-justify">
                ISBM University offers a variety of programs across different
                levels of education including undergraduate, postgraduate,
                diploma, and certificate courses. The university is committed to
                providing comprehensive education and support to its students,
                encompassing career development, skill enhancement, and
                opportunities for business incubation and mentorship.
              </p>
              <p className="text-justify">
                The university also emphasizes skill development and
                international academic programs, aiming to provide students with
                a global perspective and the necessary skills to excel in
                today's competitive job market.
              </p>
              <h3 className="fw-bold">
                Explore Programs by School at ISBM University
              </h3>
              <div class="row pt-3">
                {Array.isArray(schools) &&
                  schools.map((school) => (
                    <div key={school.id} className="p-2 col-md-4">
                      <div class="inst-box-in text-center">
                        <Link to={`/school-details/${school.name}`}>
                          {school.title}
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgrammedDetails;
