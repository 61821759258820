import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";


const Admissionprocedure  = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Admission Procedure Guidelines</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Admission Procedure Guidelines</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Admission Procedure Guidelines</h2>
            <p>
              ISBM University has multiple options available for efficient and
              secure fee payment for students. Details regarding the different
              modes of fee payment for students are given below.
            </p>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Admission Requirements</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>Photo Copies of Mark Sheets & Certificates</li>
                    <li>Four Latest Photographs</li>
                    <li>Identity Proof</li>
                    <li>Address Proof</li>
                    <li>Work Experience Certificate if any</li>
                  </ul>
                  <p>
                    <b>Note:</b> All the documents need to be attested.
                  </p>
                  <p>
                    <b>Eligibility: </b> Please refer the list of Courses for
                    details.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-4">
                <Accordion.Header>Admission Procedure</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Applicants are required to fill the application form and
                      submit/post it to ISBM University.
                      <a href="" className="text-dark">Click here</a> to see list of centers
                    </li>
                    <li>
                      Fees payment can be done any of our payment methods
                      <a href="" className="text-dark">Click here</a> see the available payment
                      options
                    </li>
                    <li>
                      A printable format of the application form is available on
                      our website.<a href="" className="text-dark">Click here</a>download the
                      admission form
                    </li>
                    <li>Address Proof</li>
                    <li>Work Experience Certificate if any</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <p className="mt-4">
              <b>
                Application Form is available at the Admission Cell, ISBM
                University, Chhura, Gariyaband, Chattisgarh on cash payment of
                900 INR.
              </b>
            </p>
            <p>OR</p>
            <p>
              <b>
                Application form can be downloaded from website and sent along
                with a DD of 900 INR in favor of 'ISBM University', payable at
                Raipur and required to be sent at the address given below:
              </b>
            </p>

            <p>
              <b>ISBM university:</b>Village-Nawapara (Kosmi) Block,
              Tehsil-Chhura, District: Gariyaband, Chhattisgarh
            </p>
            <p></p>
            <p>
              <b>Pin:</b> 493996
            </p>
            <p>
              <b>Contact:</b> +91 9373199999
            </p>
            <p>
              <b>Email:</b> info@isbmuniversity.edu.in
            </p>
            <p>
              To download admission form<a href="" className="text-dark"> Click here</a>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Admissionprocedure;
