import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import library from "../../Images/commonimg/library.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Library = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="libraryinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Library</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Library</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Library</h2>
            <img
              src={library}
              alt="Modern interior university library with seating areas"
              style={{ marginBottom: "20px" }}
            ></img>
            <p>
              Libraries are the hub of academic life of the campus. Housed in
              spacious halls, the library is well stacked with text books,
              journals, periodicals and reference material.
            </p>
            <p>
              The University supports a huge state-of-the-art library with the
              contemporary books and online system of accessibility to make
              research and learning a continuous and speedy process. The library
              is stocked with large number of books and journals in all applied
              areas of studies.
            </p>
            <p>
              It is equipped with the most popular journals both National and
              International, Newspapers, Current Magazines, books, research
              publications etc. The library is accessible to all students and
              faculty members. Reference Section contains Encyclopedias, books
              on General Knowledge and Competitive Exams, Dictionaries, previous
              volumes of journals/magazine etc. and remote access to thousands
              of library through modern software..
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Library;
