import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import bustakes from "../../Images/commonimg/bustakes.webp";
import EnquiryNow from "../common/EnquiryNow";

const IndustryIntegratedProgrammes = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Industry Integrated Programmes</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Industry Integrated Programmes</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Industry Integrated Programmes</h2>
            <p>
              The rise and boom of technological advancements in almost all
              industries is resulting in increased competition amongst working
              professionals. Most banking, finance, technology, media, and
              marketing industries are welcoming professionals who possess
              industry-specific learning and skills that are beneficial in
              improving the company's performance. Practical industry knowledge
              and understanding of effective methodologies are highly important
              for achieving profitable outcomes.
            </p>
            <p>
              In India, most working professionals feel stagnant in their
              respective jobs after a certain tenure. They believe there is no
              learning happening whatsoever and they are often unaware of newly
              introduced industry trends and global practices within their
              respective domains. This leads them to look for work-integrated
              learning programs and courses that would upgrade their skills and
              make them efficient enough to run the latest projects
              successfully.
            </p>
            <p>
              To bridge the gap of industry-specific needs and uplift the
              careers of working professionals, the ISBM University has
              introduced Industry Integrated Programmes.
            </p>
            <p>
              ISBM University is offering one of the best and most competitive
              Industry Integrated programs as a means of continuing education
              for employed professionals through the human resource development
              of specific organizations. In order to conduct these programs, the
              integrative organizations must provide physical and other
              facilities. By doing so, they are agreeing to integrate their work
              requirements with the academic requirements of the ISBM University
              for pursuing Diploma / Bachelor / Master level programs. In all
              these programs, a large emphasis is placed on self-learning in
              order to integrate as many modern technologies as necessary.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default IndustryIntegratedProgrammes;
