import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Schoolsidebaar from "./School_sidebaar";
import henry1 from "../../Images/commonimg/henry1.webp";
import henryimg from "../../Images/commonimg/henryimg.webp"
const HenryInfo = () => {
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="henryBanner innerbackimage"></div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Henry Harvin</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Schoolsidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Henry Harvin</h2>
           
            <p className="mt-3">
              Henry Harvin® is one of the leading providers of training &
              advisory services.
            </p>
            <p>
              Henry Harvin has been operational since July 2013 having offices
              across 11+ cities in US, India & UAE (including Fremont (San
              Francisco Bay Area, USA), Dubai Mainland (UAE), Noida, Delhi,
              Bangalore, Mumbai, Chennai, Hyderabad, Pune, Kochi, Chandigarh)
              with a strong team of <b>800+</b> employees and 650+ consultants.
            </p>
            <p>
              Having a portfolio of <b>800+</b> upskilling and reskilling
              training programmes across 27+ categories, Henry Harvin® has trained
              4,60,000+ individuals, conducts 7000+ classes/month and is a
              registered training vendor to more than 210+ corporates out of
              900+ corporate clients and 130+ colleges across the globe.
            </p>
            <p>
              Our business verticals include training solutions (for
              individuals, corporates & colleges), advisory services, staffing
              solutions, books & select educational solutions.
            </p>
          
            <Row>
              <div className="col-6 col-md-6">
                <div className="vision-mission-item">
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="icon-item text-center">
                        <i className="fa fa-eye icon"></i>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                      <h4>
                        <b>Our Vision</b>
                      </h4>
                      <p>
                        To reshape growth of individuals &amp; organizations
                        globally by delivering excellent training &amp; services
                        through benchmarked content, resources &amp;
                        state-of-the-art technology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <div className="vision-mission-item">
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className="icon-item text-center">
                        <i className="fa fa-bullseye icon"></i>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                      <h4>
                        <b>Our Mission</b>
                      </h4>
                      <p>
                        To continuously innovate in products, processes, people
                        &amp; markets and build the highest levels of
                        operational efficiencies to maximize customer value.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Col>
              <div>
                <img src={henry1} style={{ width: "100%" }} alt="Henry Harvin"></img>
              </div>
         
              <h2 className="text-center mt-3 pb 5">
                YOU GET ‘9 in 1 PROGRAM’
              </h2>
              <div>
                <img src={henryimg} style={{ width: "100%" }}></img>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default HenryInfo;
