import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";

import MyVerticallyCenteredModal from "./modalpop2/SpecialDevelopmentModal1";
import MyVerticallyCenteredModal1 from "./modalpop2/SpecailDevelopmentModal2";
import MyVerticallyCenteredModal2 from "./modalpop2/SpecailDevelopmentModal3";
import MyVerticallyCenteredModal3 from "./modalpop2/SpecailDevelopmentModal4";
import ENAGRAM from "../../Images/commonimg/ENAGRAM.webp";
import BV from "../../Images/commonimg/BV.webp";
import INTEL from "../../Images/commonimg/INTEL.webp";
import BONSAI from "../../Images/commonimg/BONSAI.webp";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const SpecialDevelopmentSkill = () => {
    const [modalShow2, setModalShow2] = React.useState(false);
    const [modalShow3, setModalShow3] = React.useState(false);
    const [modalShow1, setModalShow1] = React.useState(false);
    const [modalShow4, setModalShow4] = React.useState(false);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>ISBM University Special Development Cells Program </title>
        <meta name="Discover ISBM University special development cell, designed to support student success. Explore our programs for professional growth & placement assistance" />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University Special Development Cells Program "
        />
        <meta
          property="og:description"
          content="Discover ISBM University special development cell, designed to support student success. Explore our programs for professional growth & placement assistance"
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University Special Development Cells Program "
        />
        <meta
          name="twitter:description"
          content="Discover ISBM University special development cell, designed to support student success. Explore our programs for professional growth & placement assistance"
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Placement Assistance , Degree Programs , Masters Courses"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="devcellinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Special Development Cells</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Special Development Cells</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Special Development Cells</h2>
            <p className="fs-7">
              ISBM University Network for Technology Entrepreneurship &
              Leadership (INTEL)
            </p>
            <img
              src={INTEL}
              alt="Network for Technology Entrepreneurship &
              Leadership"
              onClick={() => setModalShow4(true)}
            />
            <MyVerticallyCenteredModal
              show={modalShow4}
              onHide={() => setModalShow4(false)}
            />
       
            <p className="fs-7 mt-4">
              Business Opportunities Nurtured through Support, Action and
              Incubation (BONSAI)
            </p>
            <img
              src={BONSAI}
              alt=" Business Opportunities"
              onClick={() => setModalShow1(true)}
            />
            <MyVerticallyCenteredModal1
              show={modalShow1}
              onHide={() => setModalShow1(false)}
            />
   
            <p className="fs-7 mt-4">English and Grammar Development Cell (ENAGRAM)</p>
            <img
              src={ENAGRAM}
              alt="English and Grammar Development Cell"
              onClick={() => setModalShow2(true)}
            />
            <MyVerticallyCenteredModal2
              show={modalShow2}
              onHide={() => setModalShow2(false)}
            />
            
            <p className="fs-7 mt-4">Careers and Placement Assistance Cell (BigVacancy.Com)</p>
            <img
              src={BV}
              alt="Careers and Placement"
              onClick={() => setModalShow3(true)}
            />
            <MyVerticallyCenteredModal3
              show={modalShow3}
              onHide={() => setModalShow3(false)}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SpecialDevelopmentSkill;
