import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import mahesh from "../../Images/Hon'ble Persons/mahesh.webp";
import EnquiryNow from "../common/EnquiryNow2";
import { MetaTags } from "react-meta-tags";

const Patron = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
   <MetaTags>
        <title>ISBM University: Honorable Patron Vision for Excellence</title>
        <meta
          name="description"
          content="ISBM University - Learn about our commitment to nurturing excellence in quality and affordable education. Discover the guiding influence behind our success."
        />
        <link
          rel="canonical"
          href="https://isbmuniversity.edu.in/honorable-patron"
        />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University: Honorable Patron Vision for Excellence"
        />
        <meta
          property="og:description"
          content="ISBM University - Learn about our commitment to nurturing excellence in quality and affordable education. Discover the guiding influence behind our success. "
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/honorable-patron/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University: Honorable Patron Vision for Excellence"
        />
        <meta
          name="twitter:description"
          content="ISBM University - Learn about our commitment to nurturing excellence in quality and affordable education. Discover the guiding influence behind our success."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Educational Values , ISBM University , Commitment to Education"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Patron's Message</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hon'ble Patron</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <Row>
              <Col lg={4}>
                <div className="honor_div">
                  <img className="img_honor" src={mahesh} alt="Hon'ble Patron Mr. Mahesh Agrawal" />
                </div>
              </Col>
              <Col lg={8}>
                <h2 className="honor_name">Mr. Mahesh Agrawal</h2>
                <p className="text-justify">
                  ISBM University was established in the year 2016 with an
                  objective to cater the multifaceted educational needs of the
                  millions. Its philosophy includes offering multiple courses
                  and programmes for the students, preparing them to become
                  successful in life and eventually to become responsible
                  citizens of the country. We encourage them to develop their
                  personality, by metamorphosing and nurturing them to become
                  employable as well as venture into entrepreneurship. In a
                  nutshell, we take all our efforts for academic growth & skill
                  development of the students.
                </p>
                <p className="text-justify">
                  Knowledge is the key to unlock the career of young men and
                  women. ISBM University provides the platform for learning &
                  research. Problem solving & decision making are dual
                  objectives of learning.
                </p>
                <p className="text-justify">
                  The challenges are accepted in collaboration with the
                  stakeholder's viz. other Universities, research institutions,
                  members of the faculty & society at large. The academic
                  programmes are need-based catering to the needs of mankind.
                  Excellence & relevance is a key parameter. It is to be set by
                  the academic community & drive to achieve it. It is equally
                  important we keep close to our communities both in the
                  interest of our country and our neighborhood. It will help to
                  ensure the on-going relevant academic programmes. I welcome
                  all and congratulate the students, parents for choosing our
                  University. I am sure that all stakeholders will be immensely
                  benefited by an excellent academic pursuit.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Patron;
