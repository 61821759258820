import React, { useState } from "react";
import out1 from "../../Images/icons/out1.webp";
import out2 from "../../Images/icons/out2.webp";
import out3 from "../../Images/icons/out3.webp";
import out4 from "../../Images/icons/out4.webp";

const ReadMore = ({ children, wordLimit = 20 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const words = text.split(" ");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <p className="text_feature">
        {isReadMore ? words.slice(0, wordLimit).join(" ") : text}
      </p>
      {words.length > wordLimit && (
        <button onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "Read More" : "Show Less"}
        </button>
      )}
    </>
  );
};

const Features = () => {
  return (
    <React.Fragment>
      <div className="row row-cols-1 row-cols-md-4 p-5">
        <div className="col">
          <div className="h-100 bg-blue p-4">
            <div className="d-flex justify-content-center pb-3">
              <img src={out1} alt="col-icon-1" width={50} height={50} />
            </div>
            <div className="card-body p-0">
              <h2 className="fw-bold text-white text-center">
                Startup Incubation and Placements
              </h2>
              <div className="gdlr-core-column-service-content text-center pt-3">
                <ReadMore>
                  At ISBM University, we believe in nurturing innovation and
                  empowering our students and alumni to excel in both
                  entrepreneurship and their chosen career paths.Our Startup
                  Incubation and Placements cell is established to provide the
                  essential support and resources needed for success in today's
                  dynamic job market and business landscape.
                </ReadMore>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="h-100 bg-blue p-4">
            <div className="d-flex justify-content-center pb-3">
              <img src={out2} alt="col-icon-1" width={50} height={50} />
            </div>
            <div className="card-body p-0">
              <h2 className="fw-bold text-white text-center">
                International Academic programmes
              </h2>
              <div className="gdlr-core-column-service-content text-center pt-3">
                <ReadMore>
                  We believe in providing our students with a truly
                  international education experience. Our international academic
                  programmes are designed to broaden your horizons, foster
                  cultural understanding, and prepare you for success in a
                  globalized world with collaboration of foreign universities
                  and institutes. We provide a truly world class education
                  opportunity to our students.
                </ReadMore>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="h-100 bg-blue p-4">
            <div className="d-flex justify-content-center pb-3">
              <img src={out3} alt="col-icon-1" width={50} height={50} />
            </div>
            <div className="card-body p-0">
              <h2 className="fw-bold text-white text-center">
                Skill Development
              </h2>
              <div className="gdlr-core-column-service-content text-center pt-3">
                <ReadMore>
                  ISBM University, in collaboration with the MSDE and NSDC, is
                  at the forefront of establishing exemplary institutions that
                  set new standards and embody aspirational excellence. Our
                  commitment to excellence and innovation is shaping the future
                  of education, creating a benchmark for institutions
                  nationwide. Together with the MSDE and NSDC, we are redefining
                  the educational landscape to empower students with world-class
                  learning experiences and opportunities.
                </ReadMore>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="h-100 bg-blue p-4">
            <div className="d-flex justify-content-center pb-3">
              <img src={out4} alt="col-icon-1" width={50} height={50} />
            </div>
            <div className="card-body p-0">
              <h2 className="fw-bold text-white text-center">
                Management Development Programmes
              </h2>
              <div className="gdlr-core-column-service-content text-center pt-3">
                <ReadMore>
                  We recognize the importance of continuous learning and
                  professional development for students. Our Management
                  Development Programmes (MDPs) are designed to empower you with
                  the knowledge, skills, and strategies necessary to excel in
                  the dynamic world of management and leadership.
                </ReadMore>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Features;
