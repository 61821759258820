import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import resize1 from "../../Images/commonimg/resize1.webp";
import resize2 from "../../Images/commonimg/resize2.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Laboratory = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="laboratoryinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Laboratory</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Laboratory</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Laboratory</h2>

            <p>
              ISBM University has excellent laboratory facilities for carrying
              out teaching, research and consultancy activities in various
              disciplines of design, media and mass communication.
            </p>
            <p>
              The University has state of the art labs that allow the students
              to experiment and bring to practice what they have learnt in
              theory.
            </p>

            <p>
              Laboratory at ISBM University facilitates progress of logical and
              decisive thinking skills of the students. The University has
              dedicated laboratories for physics, chemistry and biology
              programmes. Each laboratory is well equipped with sufficient
              equipments, apparatus, specimens and computerized charts as per
              the requirements of different subjects. The laboratories are
              environmental friendly and all the necessary safety measures have
              been taken while designing the laboratory.
            </p>
            <Row>
              <Col>
                <img
                  src={resize1}
                  alt="best msc colleges in india"
                  height="300px"
                ></img>
              </Col>
              <Col>
                <img
                  src={resize2}
                  alt="Best laboratory Colleges"
                  height="300px"
                ></img>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Laboratory;
