import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow";



const RTI = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <Topnav />
      <Menubar />
      <div className="innerbackimagerti">
        <div className="text_banners_abcds">
          <h1>RTI</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>RTI</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Details of PIO and Appellate Authority of ISBM University Under RTI Act 2005.</h2>
            <h3 className="fs-4 fw-bolder">Public Information Officer (PIO)</h3>
            <div>
              <p className="fs-5 mb-0">Mr. Diamond Sahu</p>
              <p className="fs-5 mb-0">Asst. Prof. School of Arts &amp; Humanities, ISBM University</p>
              <p className="fs-5 mb-0">Nawapara (Kosmi), Block &amp; Tehsil – Chhura, District – Gariyaband, Chhattisgarh – 493996</p>
              
              <p className="fs-5 mb-0">Email ID – diamondkumar.sahu@isbmuniversity.edu.in</p>
            </div>
            <h3 className="fs-4 mt-4 fw-bolder">First Appellate Authority</h3>
            <div>
              <p className="fs-5 mb-0">Dr. Barada Prasad Bhol</p>
              <p className="fs-5 mb-0">Registrar &amp; First Appellate Authority, ISBM University</p>
              <p className="fs-5 mb-0">Nawapara (Kosmi), Block &amp; Tehsil – Chhura, District – Gariyaband, Chhattisgarh – 493996</p>
        
              <p className="fs-5 mb-0">Email ID – b.bhol@isbmuniversity.edu.in</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default RTI;
