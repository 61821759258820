import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Careercounselling = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="Counsellinginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Career Counselling</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Career Counselling</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Career Counselling</h2>
          <p >
              The Career and Placement Cell is well equipped and recognised to
              provide the following services to all students:
            </p>
            <ul >
              <li>Conduct Career Opportunity Workshops, Personality Tests</li>
              <li>Conduct Job Fairs and Placement Weeks</li>
              <li>
                Provide resources on various industries, economy, technological
                advances and job opportunities
              </li>
              <li>
                Train students for preparing CV, Group Discussion, Written Tests
                and Interviews
              </li>
              <li>Conduct Mock Interview Sessions</li>
              <li>
                Sensitise students with work culture and corporate etiquettes
              </li>
              <li>
                Counsel students for a smooth transition from student to
                employee
              </li>
              <li>
                Network with Industries to identify job opportunities and skill
                / knowledge requirementsv
              </li>
              <li>Assist with Internship Programmes</li>
              <li>Facilitate Companies to recruit students from campus</li>
              <li>Maintain Alumni Database and actively engage with them</li>
              <li>
                Map future industry trends and feed information to Boards of
                Study for syllabus development
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Careercounselling;
