import React, { useState } from "react";
import logo from "../../Images/logo/isbm-university.webp";
import { Modal } from "react-bootstrap";

const AdmissionForm = () => {
 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course1: "",
  });

  // Handler for modal close

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData); // Example: Log form data to console
    // Additional logic (e.g., API call) can be added here
  };

  return (
    
        <form onSubmit={handleSubmit}>
          <div className="px-3 mb-3">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="px-3 mb-3">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="px-3 mb-3">
            <label>Phone No:</label>
            <input
              type="text"
              name="number"
              value={formData.number}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="px-3 mb-3">
            <label>Courses:</label>
            <select
              required
              name="course1"
              id="course1"
              value={formData.course1}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select Course</option>
              {/* Add all the options here */}
            </select>
          </div>
          <div className="px-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
    
  );
};

export default AdmissionForm;
