import React, { useEffect, useState } from "react";
import { Container, Row,Col,Breadcrumb, Accordion, Tab, Tabs,} from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow";


const Admissiondownload = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <Topnav />
      <Menubar />
      <div className="resourcesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Download and Resources</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Download and Resources</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Download and Resources</h2>
            <ul >
              <li>
                <a href="" className="text-purple">Download</a>
              </li>
              <li>
                <a href="" className="text-purple">ISBM University Android App</a>
              </li>
              <li>
                <a href="" className="text-purple">ISBM University iOS App</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Admissiondownload;
