import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Placementslidebaar = () => {
  const location = useLocation();

  const menuItems = [
    { path: "/about-placement", label: "About Placements" },
    { path: "/career-counselling", label: "Career Counselling" },
    { path: "/global-skills", label: "Global Skills" },
    { path: "/recruiters", label: "Recruiters" },
    {
      path: "/campus-recruitment-interview-training",
      label: "Campus Recruitment & Interview Training",
    },
    { path: "/work-at-isbmu", label: "Work at ISBM University" },
    { path: "/placement-form", label: "Placement Form" },
  ];
  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Placementslidebaar;
