import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow";
 

const Hostel = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="hostelinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Hostel</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hostel</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Hostel</h2>
            <p>
              We take special care of all requirements in a hostel for the
              development and growth of a student. We ensure that the child gets
              the homely feeling, with the best amenities and the desire to
              study hard more in a friendly atmosphere. The hygienic environment
              maintains the good health of our students and results in their
              overall physical development.
            </p>

            <p>
              Students, who come from all over India, get exposed to different
              religions and customs, therefore, a complete finishing school in
              itself is the core feature of our hostel. We pamper students by
              providing amenities and groom them to manage their routine
              independently.
            </p>

            <p>
              Tuning students to the real aspect of life, however letting them
              enjoy the comforts of reading newspapers and books, providing them
              nutritious food, safe drinking water, round the clock water supply
              including water heating systems, etc. gives them a feeling of
              being at home. All the above amenities when provided to your child
              give him/her the special nourishment to concentrate on his/her
              career growth.
            </p>

            <p>
              The efficiency of students increases when they get good company
              and friends. Students usually have a habit of studying together,
              our spacious rooms provide them a comfortable setup to study. We
              have excellent acoustics in the hostel rooms which are designed in
              such a way as to avoid any noise disturbance.
            </p>

            <p>
              Lessening the burden of daily washing of clothes and vessels, we
              have in house servants who take care of washing, ironing, and a
              dusting of the rooms regularly. We want our students to manage a
              home away from home and discipline themselves even in the absence
              of their parents. Liberal in all means, but strict in the areas of
              maintaining hygiene, manners, and discipline is the core feature
              at our Hostel.
            </p>
            <h5>
              <b>We provide:</b>
            </h5>
            <p>Nutritious Food</p>

            <p>Pure Drinking Water (Through Aquaguard and water cooler)</p>

            <p>Attached bathroom</p>

            <p>24 hour Security</p>

            <p>Doctor on Call</p>

            <p>Telephone</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Hostel;
