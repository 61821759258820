import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow";
import { MetaTags } from "react-meta-tags";

const Visionmision = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <MetaTags>
        <title>
          Mission & Values: Commitment to Quality Academic Education
        </title>
        <meta
          name="description"
          content="ISBM University stands apart with its mission and values that put student support and community at the forefront of higher education."
        />
        <link
          rel="canonical"
          href="https://isbmuniversity.edu.in/mission-values "
        />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Mission & Values: Commitment to Quality Academic Education"
        />
        <meta
          property="og:description"
          content="ISBM University stands apart with its mission and values that put student support and community at the forefront of higher education."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/mission-values/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Mission & Values: Commitment to Quality Academic Education"
        />
        <meta
          name="twitter:description"
          content="ISBM University stands apart with its mission and values that put student support and community at the forefront of higher education."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="isbm university, Student Support, Higher Education in chhattisgarh"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="mvvinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Vision and Mission</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Vision and Mission</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <section className=" cta">
              <div className="container">
                <div className="row justify-content-end">
                  <div className=" bg-white p-3 rounded col-lg-5">
                    <h2 className="mb-4 font-weight-bold text-center">
                      Our
                      <span className="text-primary"> Vision</span>
                    </h2>
                    <p className="mb-30">
                      Our vision is to be a student centric university to
                      facilitate aspiring minds with virtues of knowledge,
                      wisdom & humanity.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className=" cta2">
              <div className="container">
                <div className="row justify-content-start">
                  <div className=" bg-white p-3 rounded col-lg-5">
                    <h2 className="mb-4 font-weight-bold text-center">
                      Our
                      <span className="text-primary"> Mission</span>
                    </h2>
                    <p className="mb-30">
                      Our mission is to create strong academic transaction with
                      the state-of-art technology and continual research that
                      leads to value innovation, creation resulting in all round
                      sustainable development.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Visionmision;
