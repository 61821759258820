import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Internationalsidebaar from "../sidebars/InternationalSidebar";
import Arrival from "../../Images/international/arrival.webp";
import Currency from "../../Images/international/currency.webp";
import Climate from "../../Images/international/earth.webp";
import Device from "../../Images/international/Devices.webp";
import Admission from "../../Images/international/admission.webp";
import Register from "../../Images/international/Register.webp";
import Student from "../../Images/international/students.webp";
import EnquiryNow from "../common/EnquiryNow";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (text.length > 100) {
    return (
      <p className="card-text">
        {isReadMore ? text.slice(0, 100) : text}
        <br />
        <button
          style={{ float: "right" }}
          onClick={toggleReadMore}
          className="bg-white border-0 fs-6 text-secondary"
        >
          {isReadMore ? "...Read More" : "Show Less"}
        </button>
      </p>
    );
  } else {
    return <p className="card-text">{text}</p>;
  }
};

const Internationalstudent = () => {
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="internationalinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>International Students</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>International Students</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Internationalsidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">International Students</h2>
            <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2">
              <div className="col mb-3">
                <div className="card">
                  <img src={Arrival} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Pre-Arrival Preparation</b>
                    </h4>
                    <ReadMore>
                      Update the University on Visa Status upon Admission
                      confirmation. Bring a Medical Certificate from an
                      authorized doctor, stating fitness. Ensure vaccinations
                      for Yellow Fever, Typhoid, Hepatitis, and Covid. Notify
                      ISBM University two weeks before arrival for Airport
                      pickup, providing flight details. Carry University
                      Admission letter, original Certificates/Degrees,
                      Transcripts, and 15 passport size photographs.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Currency} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Financial Preparations</b>
                    </h4>
                    <ReadMore>
                      Consult Indian Embassy for currency limits; consider
                      Travelers Cheques or international Debit/Credit Cards.
                      Confirm FRRO/FRO process with the Indian Embassy.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Climate} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Climate and Essentials</b>
                    </h4>
                    <ReadMore>
                      Prepare for temperatures between 30 to 45 degrees Celsius
                      in summer and 20 to 25 degrees Celsius in winter (starting
                      from November). Bring warm materials like blankets,
                      quilts, and woolens. Get essential items from markets near
                      the university campus.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Device} className="card-img-top" />
                  <div className="card-body">
                    <h4 className="card-title mb-3">
                      <b>Electrical Considerations</b>
                    </h4>
                    <ReadMore>
                      Devices should operate on 240 V AC; carry adapters for
                      mobiles or laptops. Electrical sockets are 2 or 3 pin
                      (round holes); bring a converter/adapter.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Admission} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Admission Formalities</b>
                    </h4>
                    <ReadMore>
                      Present all necessary documents at the Office of
                      International Relations (OIR). Communicate with the
                      reporting officer to complete admission formalities.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Register} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Post-Arrival Obligations</b>
                    </h4>
                    <ReadMore>
                      Register student visa in FRRO/FRO/Chhattisgarh within 14
                      days. University provides support for this process (refer
                      to FRRO.pdf). Report for a medical checkup at the Office
                      of International Relations (OIR); receive advice on the
                      medical insurance process.
                    </ReadMore>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <img src={Student} className="card-img-top" />
                  <div className="card-body ">
                    <h4 className="card-title mb-3">
                      <b>Airport Arrival and Immigration</b>
                    </h4>
                    <ReadMore>
                      University arranges airport pickup for students sharing
                      flight details. Submit documents at Immigration Counter;
                      ensure passport is stamped.
                    </ReadMore>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Internationalstudent;
